const now = new Date();
const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const today = now.getTime() - midnight.getTime() > 0 ? ((now.getTime() - midnight.getTime()) / 1000).toFixed() : 0;

const configs = {
    country_code: 'ru',
    base_back_url: 'http://ru-raqib.ai:2882/api/v1/',
    // base_back_url: 'http://127.0.0.1:8000/api/v1/',
    // raqib_support_bot_url: 'tg://resolve?domain=ru_raqib_support_bot',
    raqib_support_bot_url: 'https://web.telegram.org/k/#@ru_raqib_support_bot',
    // time_locale: 'utc',
    // time_locale: 'Asia/Tehran',
    time_locale: Intl.DateTimeFormat().resolvedOptions().timeZone,   //Detect browser timezone and show as that zone
    target_location: {
        country: 'Russia',
        google_trends_country_code: 'RU',
    },
    roles: [
        {label: 'Superadmin', value: 'super_admin'},
        {label: 'Admin', value: 'admin'},
        {label: 'Viewer', value: 'viewer'},
        {label: 'Disabled', value: 'disabled'}
    ],
    projects: [
        {label: 'Map', value: 'RAQIB1'},
        {label: 'Monitoring', value: 'RAQIB6'},
        {label: 'News', value: 'RAQIB5'},
        {label: 'Enemy View', value: 'RAQIB7'},
        {label: 'Topics', value: 'RAQIB2'},
        {label: 'Image Trends', value: 'RAQIB3'},
        {label: 'Contextual Trends', value: 'RAQIB4'},
    ],
    locations: [
        {
            label: "International",
            value: "international",
            geo: {lat: 43.081238098979675, lon: 64.78127957947278},
            zoom: 3.053770845386799
        },
        {
            label: "Zabaykalsky",
            value: "zabaykalsky",
            geo: {lat: 52.11813451611753, lon: 115.9021823821445},
            zoom: 6.510021778326575
        },
        {
            label: "Yevrey",
            value: "yevrey",
            geo: {lat: 48.5278723364739, lon: 132.2176012647718},
            zoom: 8.076062291271803
        },
        {
            label: "Yaroslavl",
            value: "yaroslavl",
            geo: {lat: 57.92141308365141, lon: 39.28487037179424},
            zoom: 7.940376598901998
        },
        {
            label: "Yamalo-Nenets",
            value: "yamalo-nenets",
            geo: {lat: 67.49593770432001, lon: 74.84555947863454},
            zoom: 4.712481051449535
        },
        {
            label: "Voronezh",
            value: "voronezh",
            geo: {lat: 50.99852738830236, lon: 40.160807985641895},
            zoom: 7.384848842560343
        },
        {
            label: "Vologda",
            value: "vologda",
            geo: {lat: 51.79123588674079, lon: 36.27144532733106},
            zoom: 8.384848842560343
        },
        {
            label: "Volgograd",
            value: "volgograd",
            geo: {lat: 50.01305413000521, lon: 46.73462319781437},
            zoom: 7.066830026716111
        },
        {
            label: "Vladimir",
            value: "vladimir",
            geo: {lat: 56.04032510870096, lon: 40.571336037992424},
            zoom: 7.710583839041068
        },
        {
            label: "Ulyanovsk",
            value: "ulyanovsk",
            geo: {lat: 53.89876594960967, lon: 47.879627781285066},
            zoom: 7.66943181339141
        },
        {
            label: "Udmurt",
            value: "udmurt",
            geo: {lat: 57.28808774908929, lon: 52.83795035477429},
            zoom: 7.4966612996951065
        },
        {
            label: "Tyumen",
            value: "tyumen",
            geo: {lat: 57.65390298874813, lon: 68.88094990597205},
            zoom: 6.341878953565664
        },
        {label: "Tver", value: "tver", geo: {lat: 56.96960227458301, lon: 34.597368173362554}, zoom: 7.01023176301328},
        {label: "Tuva", value: "tuva", geo: {lat: 52.12865341021848, lon: 96.62437159228982}, zoom: 6.781916029606544},
        {label: "Tula", value: "tula", geo: {lat: 53.91767757843522, lon: 37.4983765897899}, zoom: 7.947115624440037},
        {
            label: "Tomsk",
            value: "tomsk",
            geo: {lat: 58.72473611748578, lon: 82.80377951597869},
            zoom: 6.346376048373135
        },
        {
            label: "Tatarstan",
            value: "tatarstan",
            geo: {lat: 55.383261159386386, lon: 51.047644051141056},
            zoom: 7.519479336109498
        },
        {
            label: "Tambov",
            value: "tambov",
            geo: {lat: 52.648538646948644, lon: 41.60909395164227},
            zoom: 4.903741837614907
        },
        {
            label: "Sverdlovsk",
            value: "sverdlovsk",
            geo: {lat: 58.338357465844624, lon: 62.1146466895724},
            zoom: 6.626124367238219
        },
        {
            label: "Stavropol",
            value: "stavropol",
            geo: {lat: 44.98128759471793, lon: 43.70937679625574},
            zoom: 7.448907844614728
        },
        {
            label: "Smolensk",
            value: "smolensk",
            geo: {lat: 54.8460492340215, lon: 32.95999577630474},
            zoom: 7.3814862474228375
        },
        {
            label: "Saratov",
            value: "saratov",
            geo: {lat: 51.66205131962258, lon: 46.676585050250786},
            zoom: 7.2339442772717675
        },
        {
            label: "Samara",
            value: "samara",
            geo: {lat: 53.277996217917675, lon: 50.80563931403039},
            zoom: 7.2339442772717675
        },
        {
            label: "Sakhalin",
            value: "sakhalin",
            geo: {lat: 50.305258033602236, lon: 143.01465439675306},
            zoom: 7.338697574352273
        },
        {
            label: "Sakha",
            value: "sakha",
            geo: {lat: 67.03370301175781, lon: 130.83195262190887},
            zoom: 4.401547998044985
        },
        {
            label: "Ryazan",
            value: "ryazan",
            geo: {lat: 54.340580595790364, lon: 40.869128182965675},
            zoom: 7.847370786462588
        },
        {
            label: "Rostov",
            value: "rostov",
            geo: {lat: 47.48181389737772, lon: 41.04230101932648},
            zoom: 7.5556984953456645
        },
        {
            label: "Pskov",
            value: "pskov",
            geo: {lat: 57.19554246905758, lon: 29.229247760100677},
            zoom: 7.764286497698614
        },
        {
            label: "Primorsky",
            value: "primorsky",
            geo: {lat: 45.26406086144726, lon: 135.13805358384252},
            zoom: 7.232264354727411
        },
        {label: "Perm", value: "perm", geo: {lat: 59.227883221822246, lon: 56.16577340462398}, zoom: 7.070879966297382},
        {
            label: "Penza",
            value: "penza",
            geo: {lat: 53.16473137015138, lon: 46.08875113900884},
            zoom: 7.545064135068721
        },
        {
            label: "Orenburg",
            value: "orenburg",
            geo: {lat: 51.898916555624396, lon: 55.02307011460497},
            zoom: 7.298640744438108
        },
        {
            label: "Oryol",
            value: "oryol",
            geo: {lat: 52.88780842574203, lon: 36.630280419281355},
            zoom: 8.052151446707708
        },
        {label: "Omsk", value: "omsk", geo: {lat: 56.03667951583757, lon: 73.98777900170028}, zoom: 6.4871679400190905},
        {
            label: "Novosibirsk",
            value: "novosibirsk",
            geo: {lat: 55.68387147030713, lon: 82.5933690225415},
            zoom: 6.214173952723569
        },
        {
            label: "Novgorod",
            value: "novgorod",
            geo: {lat: 58.30751054321483, lon: 32.6757061071425},
            zoom: 7.089611176479526
        },
        {
            label: "North Ossetia-Alania",
            value: "north ossetia-alania",
            geo: {lat: 43.027754564962684, lon: 44.498320744538795},
            zoom: 9.15680694872285
        },
        {
            label: "Nizhegorod",
            value: "nizhegorod",
            geo: {lat: 56.40357724715849, lon: 44.61646271884691},
            zoom: 7.08459227471418
        },
        {
            label: "Nenets",
            value: "nenets",
            geo: {lat: 68.0475081708592, lon: 57.913178193660116},
            zoom: 6.348387688830387
        },
        {
            label: "Murmansk",
            value: "murmansk",
            geo: {lat: 68.06840012146719, lon: 34.9393396647147},
            zoom: 6.271935270276635
        },
        {
            label: "Moskva",
            value: "moskva",
            geo: {lat: 55.723757040408735, lon: 37.67827593510465},
            zoom: 7.6080285515817545
        },
        {
            label: "Moscow City",
            value: "moscow city",
            geo: {lat: 55.74755452980572, lon: 37.636877236109285},
            zoom: 10.21070882721927
        },
        {
            label: "Mordovia",
            value: "mordovia",
            geo: {lat: 54.23189115518431, lon: 44.695353260660006},
            zoom: 8.054373881700712
        },
        {
            label: "Mariy-El",
            value: "mariy-el",
            geo: {lat: 56.66407372275096, lon: 48.269726565039086},
            zoom: 8.120631812445158
        },
        {
            label: "Magadan",
            value: "magadan",
            geo: {lat: 62.96635609671855, lon: 154.69558426584973},
            zoom: 6.0499453994503325
        },
        {
            label: "Lipetsk",
            value: "lipetsk",
            geo: {lat: 52.654689686504916, lon: 39.13822473949406},
            zoom: 8.415362907219116
        },
        {
            label: "Leningrad",
            value: "leningrad",
            geo: {lat: 60.22151694209468, lon: 33.586325146954366},
            zoom: 6.432183171709594
        },
        {
            label: "Kursk",
            value: "kursk",
            geo: {lat: 51.69750724227512, lon: 35.96499467206586},
            zoom: 7.776798870500901
        },
        {
            label: "Kurgan",
            value: "kurgan",
            geo: {lat: 55.662996681552706, lon: 65.76388286385378},
            zoom: 6.97044917228062
        },
        {
            label: "Krasnoyarsk",
            value: "krasnoyarsk",
            geo: {lat: 68.72422586992518, lon: 95.27058172686495},
            zoom: 5.10629637417632
        },
        {
            label: "Krasnodar",
            value: "krasnodar",
            geo: {lat: 45.17672659345038, lon: 39.63128756234988},
            zoom: 7.815619385247104
        },
        {
            label: "Kostroma",
            value: "kostroma",
            geo: {lat: 58.48624369753964, lon: 43.70019938403315},
            zoom: 7.2534113465303545
        },
        {label: "Komi", value: "komi", geo: {lat: 64.60561703353157, lon: 57.1790576545715}, zoom: 5.567763421511316},
        {
            label: "Kirov",
            value: "kirov",
            geo: {lat: 58.451962755711754, lon: 50.05957096581041},
            zoom: 7.082931893830109
        },
        {
            label: "Khanty-Mansiysk",
            value: "khanty-mansiysk",
            geo: {lat: 62.15511083960242, lon: 71.10069827756676},
            zoom: 6.116521502379163
        },
        {
            label: "Khakassia",
            value: "khakassia",
            geo: {lat: 53.29134452443328, lon: 90.2732914722748},
            zoom: 7.993619273658199
        },
        {
            label: "Khabarovsk",
            value: "khabarovsk",
            geo: {lat: 58.21681405654282, lon: 139.09286537054302},
            zoom: 6.231890169653266
        },
        {
            label: "Kemerovo",
            value: "kemerovo",
            geo: {lat: 54.985943248531754, lon: 87.10371337775176},
            zoom: 7.611629552959514
        },
        {
            label: "Karelia",
            value: "karelia",
            geo: {lat: 63.927552413526264, lon: 33.07370718208658},
            zoom: 6.6510769675895025
        },
        {
            label: "Karachay-Cherkess",
            value: "karachay-cherkess",
            geo: {lat: 43.66589872398149, lon: 41.8676618414035},
            zoom: 8.847822821949059
        },
        {
            label: "Kamchatka",
            value: "kamchatka",
            geo: {lat: 56.077621516611295, lon: 159.48769113692697},
            zoom: 6.109657624259224
        },
        {
            label: "Kaluga",
            value: "kaluga",
            geo: {lat: 54.487135696500374, lon: 36.2922181750632},
            zoom: 7.748405773914122
        },
        {
            label: "Kalmykia",
            value: "kalmykia",
            geo: {lat: 46.363448870725655, lon: 45.5913979181645},
            zoom: 7.134961393066779
        },
        {
            label: "Kaliningrad",
            value: "kaliningrad",
            geo: {lat: 54.69184570831828, lon: 21.4783044993522},
            zoom: 8.662630639751283
        },
        {
            label: "Kabardino-Balkar",
            value: "kabardino-balkar",
            geo: {lat: 43.42061673498058, lon: 43.51601663689978},
            zoom: 8.439366968845183
        },
        {
            label: "Ivanovo",
            value: "ivanovo",
            geo: {lat: 56.98749143671257, lon: 41.77082864630936},
            zoom: 8.254259982863129
        },
        {
            label: "Irkutsk",
            value: "irkutsk",
            geo: {lat: 57.30536642195635, lon: 110.44235059445252},
            zoom: 5.297538593445076
        },
        {
            label: "Ingushetia",
            value: "ingushetia",
            geo: {lat: 43.1552170165111, lon: 45.06843867182844},
            zoom: 9.404956008161113
        },
        {
            label: "Altay",
            value: "altay",
            geo: {lat: 50.765419618187494, lon: 87.21626251534863},
            zoom: 7.680418968215948
        },
        {
            label: "Dagestan",
            value: "dagestan",
            geo: {lat: 42.61745435401406, lon: 47.43710983305426},
            zoom: 8.134550870885077
        },
        {
            label: "St. Petersburg",
            value: "st. petersburg",
            geo: {lat: 59.85686747824387, lon: 30.30573523254519},
            zoom: 9.55248197131295
        },
        {
            label: "Chuvash",
            value: "chuvash",
            geo: {lat: 55.52057915121271, lon: 47.66831331623101},
            zoom: 8.425131428522828
        },
        {
            label: "Chukotka",
            value: "chukotka",
            geo: {lat: 66.56404908983137, lon: 167.6754653947112},
            zoom: 6.0624202445742235
        },
        {
            label: "Chelyabinsk",
            value: "chelyabinsk",
            geo: {lat: 54.27570473082072, lon: 60.5278259552125},
            zoom: 6.9304379169356904
        },
        {
            label: "Chechnya",
            value: "chechnya",
            geo: {lat: 43.30158933612728, lon: 45.877106380685476},
            zoom: 9.652212862374697
        },
        {
            label: "Buryatiya",
            value: "buryatiya",
            geo: {lat: 54.46389343519219, lon: 114.28568011174212},
            zoom: 6.399882163645427
        },
        {
            label: "Bryansk",
            value: "bryansk",
            geo: {lat: 52.91284944439306, lon: 33.41191368093756},
            zoom: 7.7335265971499645
        },
        {
            label: "Belgorod",
            value: "belgorod",
            geo: {lat: 50.71215491236421, lon: 38.000463267865705},
            zoom: 7.958500081471612
        },
        {
            label: "Bashkortostan",
            value: "bashkortostan",
            geo: {lat: 53.698142942172225, lon: 56.72713599726997},
            zoom: 7.595504607780044
        },
        {
            label: "Astrakhan",
            value: "astrakhan",
            geo: {lat: 46.88311055411328, lon: 47.89324901723685},
            zoom: 7.822226076192202
        },
        {
            label: "Arkhangelsk",
            value: "arkhangelsk",
            geo: {lat: 63.277823675287024, lon: 42.88698798583348},
            zoom: 6.134246468382759
        },
        {label: "Amur", value: "amur", geo: {lat: 53.65402545309837, lon: 128.66026054286985}, zoom: 6.297531028180676},
        {
            label: "Altay Kray",
            value: "altay kray",
            geo: {lat: 52.46201864882092, lon: 83.48584602195326},
            zoom: 6.791737798818273
        },
        {
            label: "Adygeya",
            value: "adygeya",
            geo: {lat: 44.53563833985278, lon: 40.14647650956143},
            zoom: 8.627983685935114
        },
    ],
    raqib1: {
        mapsOsmProvider: {
            token: "pk.eyJ1IjoibWZyYXNvdWxuZWphZCIsImEiOiJjbGM0cTR6aHkwcW8xM3ZwZ3hoYTNqNjY5In0.A7GD0TuHDQuzblYcGyh25g",
            attribution: {
                show: false,
                text: ""
            },
            colors: {
                'TRENDS24': [113, 128, 150],
                'TRENDSMAP': [113, 128, 150],
                'GOOGLE_TRENDS': [56, 161, 105],
                'TWITTER': [26, 32, 44],
                'RSS_INSTAGRAM': [229, 62, 62],
                'RSS_FACEBOOK': [43, 108, 176],
                'RSS_YOUTUBE': [128, 90, 213],
                'RSS_TIKTOK': [213, 63, 140],
                'RSS_TELEGRAM': [0, 136, 204],
                'RSS_OTHER': [214, 158, 46],
                'RSS_VK': [49, 151, 149],
                'RSS_OK': [0, 181, 216]
            },
            deckGl: {
                showCoordinates: false,
                controller: {
                    doubleClickZoom: false,
                    keyboard: false,
                    scrollZoom: false,
                    touchZoom: false,
                    dragPan: false
                },
                // lightStyle: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
                lightStyle: 'mapbox://styles/mapbox/satellite-v9',
                // darkStyle: 'mapbox://styles/mapbox/dark-v9',
                darkStyle: 'mapbox://styles/mapbox/satellite-v9',
                terrain: {source: 'mapbox-dem', exaggeration: 1.5},
                scrollZoom: false,
                touchZoom: false,
                keyboard: false,
                doubleClickZoom: false,
                initial_states: {
                    longitude: 97.1536052095553,
                    latitude: 63.489228913192996,
                    zoom: 3.1439206934142696,
                    pitch: 0,
                    bearing: 0
                }
            }
        },
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        sources: [
            {label: 'Trends24', value: 'TRENDS24'},
            {label: 'Trendsmap', value: 'TRENDSMAP'},
            {label: 'Google', value: 'GOOGLE_TRENDS'},
            {label: 'X', value: 'TWITTER'},
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'}
        ],
        mapMinFontSize: 15,
        mapMaxFontSize: 30,
        mapsSourcesVals: {
            // TRENDSMAP: 'TRENDSMAP',
            TRENDS24: 'TRENDS24',
            GOOGLE_TRENDS: 'GOOGLE_TRENDS',
            TWITTER: 'TWITTER',
            RSS_FACEBOOK: 'RSS_FACEBOOK',
            RSS_INSTAGRAM: 'RSS_INSTAGRAM',
            RSS_TIKTOK: 'RSS_TIKTOK',
            RSS_YOUTUBE: 'RSS_YOUTUBE',
            RSS_TELEGRAM: 'RSS_TELEGRAM',
            RSS_OTHER: 'RSS_OTHER',
            RSS_VK: 'RSS_VK',
            RSS_OK: 'RSS_OK'
        },
        mapsFilters: {
            timeranges: [
                {label: 'Last Hour', value: 3600},
                {label: 'Last 24 Hours', value: 86400},
                {label: 'Last 7 Days', value: 604800},
                {label: 'Last 30 Days', value: 2592000}
            ],
        }
    },
    raqib2: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib3: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib4: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib5: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib6: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        timeranges: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        social_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib7: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    articles: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        colors: {
            'GOOGLE_TRENDS': [56, 161, 105],
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46]
        }
    },
    sources: [
        // {label: 'Trendsmap', value: 'TRENDSMAP'},
        {label: 'Trends24', value: 'TRENDS24'},
        {label: 'Google Trends', value: 'GOOGLE_TRENDS'},
        // {label: 'X Trends', value: 'TWITTER_TRENDS'},
        {label: 'Commoninja.com', value: 'COMMONINJA'},
        {label: 'Observer', value: 'NITTER'},
        {label: 'RSS', value: 'RSS'}
    ],
    rss_platforms: [
        {label: 'Instagram', value: 'RSS_INSTAGRAM'},
        {label: 'Facebook', value: 'RSS_FACEBOOK'},
        {label: 'X', value: 'RSS_TWITTER'},
        {label: 'Telegram', value: 'RSS_TELEGRAM'},
        {label: 'TikTok', value: 'RSS_TIKTOK'},
        {label: 'YouTube', value: 'RSS_YOUTUBE'},
        {label: 'Web', value: 'RSS_OTHER'},
        {label: 'Vk', value: 'RSS_VK'},
        {label: 'Ok', value: 'RSS_OK'}
    ]
}
export default configs;