const now = new Date();
const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const today = now.getTime() - midnight.getTime() > 0 ? ((now.getTime() - midnight.getTime()) / 1000).toFixed() : 0;

const configs = {
    country_code: 'pa',
    base_back_url: 'http://pa-raqib.ai:9092/api/v1/',
    // base_back_url: 'http://127.0.0.1:8000/api/v1/',
    // raqib_support_bot_url: 'tg://resolve?domain=bh_raqib_support_bot',
    raqib_support_bot_url: 'https://web.telegram.org/k/#@bh_raqib_support_bot',
    // time_locale: 'utc',
    // time_locale: 'Asia/Tehran',
    time_locale: Intl.DateTimeFormat().resolvedOptions().timeZone,   //Detect browser timezone and show as that zone
    target_location: {
        country: 'Pakistan',
        google_trends_country_code: 'PA',
    },
    roles: [
        {label: 'Superadmin', value: 'super_admin'},
        {label: 'Admin', value: 'admin'},
        {label: 'Viewer', value: 'viewer'},
        {label: 'Disabled', value: 'disabled'}
    ],
    projects: [
        {label: 'Map', value: 'RAQIB1'},
        {label: 'Monitoring', value: 'RAQIB6'},
        {label: 'News', value: 'RAQIB5'},
        {label: 'Enemy View', value: 'RAQIB7'},
        {label: 'Topics', value: 'RAQIB2'},
        {label: 'Image Trends', value: 'RAQIB3'},
        {label: 'Contextual Trends', value: 'RAQIB4'},
    ],
    locations: [
        {
            label: "International",
            value: "international",
            geo: {lat: 25.992480764878515, lon: 50.56518163798727},
            zoom: 9.2
            ,
        },
        {
            label: "Khyber Pakhtunkhwa",
            value: "khyber pakhtunkhwa",
            geo: {lat: 34.187269674717705, lon: 71.8128211991445},
            zoom: 6.486479023139396
        },
        {
            label: "Sindh",
            value: "sindh",
            geo: {lat: 26.158571714901544, lon: 68.65605138834006},
            zoom: 7.0844026245543015
        },
        {
            label: "Punjab",
            value: "punjab",
            geo: {lat: 30.677880676325348, lon: 72.06756184247138},
            zoom: 6.613542408622977
        },
        {
            label: "Azad Jammu and Kashmir",
            value: "azad jammu and kashmir",
            geo: {lat: 33.84015590544777, lon: 76.17967012471841},
            zoom: 7.618760787113207
        },
        {
            label: "Gilgit-Baltistan",
            value: "gilgit-baltistan",
            geo: {lat: 35.86462141976855, lon: 74.87006772098168},
            zoom: 7.739218508230455
        },
        {
            label: "Balochistan",
            value: "balochistan",
            geo: {lat: 27.787106733143638, lon: 65.65841381510512},
            zoom: 6.584076250201553
        },
        {
            label: "Islamabad",
            value: "islamabad",
            geo: {lat: 33.692372326478804, lon: 73.0777974524541},
            zoom: 10.925576459257478
        }
    ],
    raqib1: {
        mapsOsmProvider: {
            token: "pk.eyJ1IjoibWZyYXNvdWxuZWphZCIsImEiOiJjbGM0cTR6aHkwcW8xM3ZwZ3hoYTNqNjY5In0.A7GD0TuHDQuzblYcGyh25g",
            attribution: {
                show: false,
                text: ""
            },
            colors: {
                'TRENDS24': [113, 128, 150],
                'TRENDSMAP': [113, 128, 150],
                'GOOGLE_TRENDS': [56, 161, 105],
                'TWITTER': [26, 32, 44],
                'RSS_INSTAGRAM': [229, 62, 62],
                'RSS_FACEBOOK': [43, 108, 176],
                'RSS_YOUTUBE': [128, 90, 213],
                'RSS_TIKTOK': [213, 63, 140],
                'RSS_TELEGRAM': [0, 136, 204],
                'RSS_OTHER': [214, 158, 46],
                'RSS_VK': [49, 151, 149],
                'RSS_OK': [0, 181, 216]
            },
            deckGl: {
                showCoordinates: false,
                controller: {
                    doubleClickZoom: false,
                    keyboard: false,
                    scrollZoom: false,
                    touchZoom: false,
                    dragPan: false
                },
                // lightStyle: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
                lightStyle: 'mapbox://styles/mapbox/satellite-v9',
                // darkStyle: 'mapbox://styles/mapbox/dark-v9',
                darkStyle: 'mapbox://styles/mapbox/satellite-v9',
                terrain: {source: 'mapbox-dem', exaggeration: 1.5},
                scrollZoom: false,
                touchZoom: false,
                keyboard: false,
                doubleClickZoom: false,
                initial_states: {
                    longitude: 70.97363793637231,
                    latitude: 30.578204027143467,
                    zoom: 5.384198697406592,
                    pitch: 0,
                    bearing: 0
                }
            }
        },
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        sources: [
            {label: 'Trends24', value: 'TRENDS24'},
            {label: 'Trendsmap', value: 'TRENDSMAP'},
            {label: 'Google', value: 'GOOGLE_TRENDS'},
            {label: 'X', value: 'TWITTER'},
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'}
        ],
        mapMinFontSize: 15,
        mapMaxFontSize: 30,
        mapsSourcesVals: {
            // TRENDSMAP: 'TRENDSMAP',
            TRENDS24: 'TRENDS24',
            GOOGLE_TRENDS: 'GOOGLE_TRENDS',
            TWITTER: 'TWITTER',
            RSS_FACEBOOK: 'RSS_FACEBOOK',
            RSS_INSTAGRAM: 'RSS_INSTAGRAM',
            RSS_TIKTOK: 'RSS_TIKTOK',
            RSS_YOUTUBE: 'RSS_YOUTUBE',
            RSS_TELEGRAM: 'RSS_TELEGRAM',
            RSS_OTHER: 'RSS_OTHER',
            RSS_VK: 'RSS_VK',
            RSS_OK: 'RSS_OK'
        },
        mapsFilters: {
            timeranges: [
                {label: 'Last Hour', value: 3600},
                {label: 'Last 24 Hours', value: 86400},
                {label: 'Last 7 Days', value: 604800},
                {label: 'Last 30 Days', value: 2592000}
            ],
        }
    },
    raqib2: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib3: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib4: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib5: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib6: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        timeranges: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        social_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib7: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    articles: {
        // refresh page after this amount of time
        refresh_interval_default: 360000,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        colors: {
            'GOOGLE_TRENDS': [56, 161, 105],
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46]
        }
    },
    sources: [
        // {label: 'Trendsmap', value: 'TRENDSMAP'},
        {label: 'Trends24', value: 'TRENDS24'},
        {label: 'Google Trends', value: 'GOOGLE_TRENDS'},
        // {label: 'X Trends', value: 'TWITTER_TRENDS'},
        {label: 'Commoninja.com', value: 'COMMONINJA'},
        {label: 'Observer', value: 'NITTER'},
        {label: 'RSS', value: 'RSS'}
    ],
    rss_platforms: [
        {label: 'Instagram', value: 'RSS_INSTAGRAM'},
        {label: 'Facebook', value: 'RSS_FACEBOOK'},
        {label: 'X', value: 'RSS_TWITTER'},
        {label: 'Telegram', value: 'RSS_TELEGRAM'},
        {label: 'TikTok', value: 'RSS_TIKTOK'},
        {label: 'YouTube', value: 'RSS_YOUTUBE'},
        {label: 'Web', value: 'RSS_OTHER'},
        {label: 'Vk', value: 'RSS_VK'},
        {label: 'Ok', value: 'RSS_OK'}
    ]
}
export default configs;