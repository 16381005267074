import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Switch,
    Textarea,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import moment from 'moment-timezone';

import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from 'react';
import {configs} from '../../Configs';
import ReactSelect from 'react-select';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from '../Theme/LocaleProvider';

export const AddUpdateResourceModal = ({
                                           editable = false,
                                           defaultValues = {},
                                           onSuccess = () => {
                                           },
                                           ...rest
                                       }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const [showRssPlatform, setShowRssPlatform] = useState(false);
    const [showCommoninjaFields, setShowCommoninjaFields] = useState(false);
    const [nitterSelected, setNitterSelected] = useState(false);

    const isValidUUID = (uuid) => {
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidPattern.test(uuid);
    }

    const isValidURL = (url) => {
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(url);
    }

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const {isOpen, onOpen, onClose} = useDisclosure();

    const toast = useToast();
    const {resourceId} = useParams();

    // set default source if not already done
    if (!('source' in defaultValues)) {
        defaultValues.source = 'TRENDS24';
        defaultValues.rss_platform = 'RSS_INSTAGRAM';
    }

    // set default timezone to utc
    if (!('timezone' in defaultValues)) {
        defaultValues.timezone = 'UTC'
    }

    const {
        handleSubmit,
        register,
        watch,
        control,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});

    const [factions, setFactions] = useState([]);

    useEffect(() => {
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    const onSubmit = async (values) => {
        try {
            // just send the values
            values.projects = values.projects?.map((project) => project.value);
            values.factions = values.factions?.map((faction) => faction.value);

            if (values?.location) {
                values.location = values.location?.map((loc) => ({
                    'state': loc?.value || loc?.state,
                    'geo': loc.geo
                }));
            }

            // if (values.source === 'RSS') {
            if (!['RSS', 'COMMONINJA'].includes(values.source)) {
                // values.rss_platform = values.rss_platform ? defaultValues.rss_platform : configs.rss_platform[0].value;
                // } else {
                delete values.rss_platform
            }

            // add origin url to exact target url (as it is from twitter)
            const the_target = values.target.replace(/^(https?:\/\/)?(www\.)?(twitter\.com\/|x\.com\/)?@?/, '');
            if (values.source === 'NITTER' && the_target) {
                values.origin_url = 'https://x.com/' + the_target;
                values.timezone = 'UTC'
            }

            if (editable) {
                await axiosInstance.put(`/resource/${resourceId}`, values);
            } else {
                await axiosInstance.post(`/resource/create/`, values);
            }

            toast({
                title: editable ? intl.formatMessage({
                    id: 'resources.updated',
                    defaultMessage: "Resource Updated"
                }) : intl.formatMessage({id: 'resources.created', defaultMessage: "Resource Created"}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    useEffect(() => {
        setShowRssPlatform(["RSS", "COMMONINJA"].includes(defaultValues.source));
        setNitterSelected(defaultValues.source === "NITTER");
        setShowCommoninjaFields(defaultValues.source === 'COMMONINJA');
    }, [])

    const bgColor = useColorModeValue("gray.300", "gray.600");

    const theLocations = configs.locations.map(loc => ({
        ...loc,
        label: intl.formatMessage({
            id: `resources.${loc.value !== 'all' ? loc.value : loc.label}`,
            defaultMessage: loc.label
        })
    }));

    return (<Box {...rest}>
        <Button w="100%" colorScheme="green" onClick={onOpen}>
            {editable ? intl.formatMessage({
                id: 'resources.updateResource',
                defaultMessage: "UPDATE RESOURCE"
            }) : intl.formatMessage({id: 'resources.addResource', defaultMessage: "ADD RESOURCE"})}
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base:"full", md:"xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            {editable ? intl.formatMessage({
                                id: 'resources.updateResource',
                                defaultMessage: "UPDATE RESOURCE"
                            }) : intl.formatMessage({
                                id: 'resources.addResource',
                                defaultMessage: "ADD RESOURCE"
                            })}
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.title}>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: 'resources.title',
                                    defaultMessage: 'Resource Title'
                                })}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("title", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "resources.title",
                                                    defaultMessage: "Title"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.title",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 55,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.title",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 55
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.target}>
                            <Input
                                placeholder={intl.formatMessage({id: 'resources.target', defaultMessage: 'Target'})}
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("target", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "resources.target",
                                                    defaultMessage: "Target"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.target",
                                                    defaultMessage: "Target"
                                                }),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 512,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.target",
                                                    defaultMessage: "Target"
                                                }),
                                                length: 512
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.target && errors.target.message}
                            </FormErrorMessage>
                        </FormControl>

                        {!nitterSelected &&
                            <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                                <FormControl isInvalid={errors.timezone}>
                                    <Controller
                                        rules={() => ({
                                            required: intl.formatMessage(
                                                {
                                                    id: "validation.fieldRequired",
                                                    defaultMessage: "{field} is required"
                                                },
                                                {
                                                    field: intl.formatMessage(
                                                        {
                                                            id: "resources.timezone",
                                                            defaultMessage: "Timezone"
                                                        }
                                                    )
                                                }
                                            )
                                        })}
                                        name="timezone"
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <Select
                                                variant="filled"
                                                size="lg"
                                                mt={6}
                                                value={value}
                                                onChange={onChange}
                                            >
                                                {moment.tz.names().map((zone) => (
                                                    <option key={"tz:" + zone} value={zone}>{zone}</option>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors.timezone && errors.timezone.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </span>
                        }

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                            <FormControl isInvalid={errors.source}>
                            <Controller
                                rules={() => {
                                    if (showRssPlatform) {
                                        return {
                                            required: intl.formatMessage(
                                                {
                                                    id: "validation.fieldRequired",
                                                    defaultMessage: "{field} is required"
                                                },
                                                {
                                                    field: intl.formatMessage(
                                                        {
                                                            id: "resources.source",
                                                            defaultMessage: "Source"
                                                        }
                                                    )
                                                }
                                            )
                                        }
                                    }
                                }}
                                name="source"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        variant="filled"
                                        size="lg"
                                        mt={6}
                                        value={value}
                                        onChange={(e) => {
                                            setShowRssPlatform(["RSS", "COMMONINJA"].includes(e.target.value));
                                            setShowCommoninjaFields(e.target.value === 'COMMONINJA');
                                            setNitterSelected(e.target.value === 'NITTER');
                                            return onChange(e)
                                        }}
                                    >
                                        {configs.sources.map((source) => (
                                            <option key={"source:" + source.value}
                                                    value={source.value}>{source.label}</option>
                                        ))}
                                    </Select>
                                )}
                            />
                            <FormErrorMessage>
                                {errors.source && errors.source.message}
                            </FormErrorMessage>
                        </FormControl>
                        </span>

                        {showRssPlatform &&
                            <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                                <FormControl isInvalid={errors.rss_platform}>
                                <Controller
                                    validate={(value) => (
                                        watch("source") === "RSS" && value.length === 0
                                            ? intl.formatMessage(
                                                {
                                                    id: "validation.fieldRequired",
                                                    defaultMessage: "{field} is required"
                                                },
                                                {
                                                    field: intl.formatMessage(
                                                        {
                                                            id: "resources.rssPlatform",
                                                            defaultMessage: "Rss Platform"
                                                        }
                                                    )
                                                }
                                            )
                                            : undefined
                                    )}
                                    name="rss_platform"
                                    control={control}
                                    render={({field: {onChange, value}}) => {
                                        return <Select
                                            variant="filled"
                                            size="lg"
                                            mt={6}
                                            value={value || ''}
                                            onChange={onChange}
                                        >
                                            {configs.rss_platforms.map((rss_platform) => (
                                                <option key={"rss_platform:" + rss_platform.value}
                                                        value={rss_platform.value}>{rss_platform.label}</option>
                                            ))}
                                        </Select>
                                    }}
                                />
                                <FormErrorMessage>
                                    {errors.rss_platform && errors.rss_platform.message}
                                </FormErrorMessage>
                                </FormControl>
                            </span>}

                        {showCommoninjaFields &&
                            <FormControl isInvalid={errors.source_id}>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: 'resources.sourceId',
                                        defaultMessage: 'Source ID'
                                    })}
                                    background={bgColor}
                                    type="text"
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    name="source_id"
                                    {...register('source_id', {
                                        required: watch('source') === "COMMONINJA" ? intl.formatMessage(
                                            {
                                                id: "validation.fieldRequired",
                                                defaultMessage: "{field} is required"
                                            },
                                            {
                                                field: intl.formatMessage(
                                                    {
                                                        id: "resources.sourceId",
                                                        defaultMessage: "Source ID"
                                                    }
                                                )
                                            }
                                        ) : false,
                                        validate: (value) => isValidUUID(value) || intl.formatMessage(
                                            {
                                                id: "validation.fieldInvalid",
                                                defaultMessage: "{field} is invalid"
                                            },
                                            {
                                                field: intl.formatMessage(
                                                    {
                                                        id: "resources.sourceId",
                                                        defaultMessage: "Source ID"
                                                    }
                                                )
                                            }
                                        )
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.source_id && errors.source_id.message}
                                </FormErrorMessage>
                            </FormControl>}

                        {(showCommoninjaFields || showRssPlatform) &&
                            <FormControl isInvalid={errors.origin_url}>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: 'resources.originUrl',
                                        defaultMessage: 'Origin URL'
                                    })}
                                    background={bgColor}
                                    type="text"
                                    variant="filled"
                                    size="lg"
                                    mt={6}
                                    name="origin_url"
                                    {...register('origin_url', {
                                        required: watch('source') === "COMMONINJA" ? intl.formatMessage(
                                            {
                                                id: "validation.fieldRequired",
                                                defaultMessage: "{field} is required"
                                            },
                                            {
                                                field: intl.formatMessage(
                                                    {
                                                        id: "resources.originUrl",
                                                        defaultMessage: "Origin URL"
                                                    }
                                                )
                                            }
                                        ) : false,
                                        validate: (value) => isValidURL(value) || intl.formatMessage(
                                            {
                                                id: "validation.fieldInvalid",
                                                defaultMessage: "{field} is invalid"
                                            },
                                            {
                                                field: intl.formatMessage(
                                                    {
                                                        id: "resources.originUrl",
                                                        defaultMessage: "Origin URL"
                                                    }
                                                )
                                            }
                                        ),
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.origin_url && errors.origin_url.message}
                                </FormErrorMessage>
                            </FormControl>}

                        <FormControl isInvalid={errors.projects}>
                            <Controller
                                rules={{
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "resources.projects",
                                                    defaultMessage: "Projects"
                                                }
                                            )
                                        }
                                    )
                                }}
                                name="projects"
                                control={control}
                                render={({field: {onChange, value}}) => (<ReactSelect
                                    value={value}
                                    onChange={onChange}
                                    placeholder={intl.formatMessage({
                                        id: 'resources.projects',
                                        defaultMessage: 'Projects'
                                    })}
                                    isMulti
                                    // options={['TRENDS24', 'GOOGLE_TRENDS'].includes(watch('source')) ? configs.projects.filter((prj) => prj.value === 'RAQIB1'): configs.projects}
                                    options={showRssPlatform || nitterSelected ? configs.projects : configs.projects.filter((prj) => prj.value === 'RAQIB1')}
                                    closeMenuOnSelect={false}
                                    classNames={
                                        {
                                            control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                        }
                                    }
                                    styles={{
                                        control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                        menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                        option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                    }}
                                />)
                                }
                            />
                            <FormErrorMessage>
                                {errors.projects && errors.projects.message}
                            </FormErrorMessage>
                        </FormControl>
                        {(watch('projects') || []).map(prj => prj.value).includes('RAQIB2') &&
                            <FormControl isInvalid={errors.factions}>
                                <Controller
                                    rules={{
                                        required: intl.formatMessage(
                                            {
                                                id: "validation.fieldRequired",
                                                defaultMessage: "{field} is required"
                                            },
                                            {
                                                field: intl.formatMessage(
                                                    {
                                                        id: "resources.factions",
                                                        defaultMessage: "Topic Tags, Factions"
                                                    }
                                                )
                                            }
                                        )
                                    }}
                                    name="factions"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <ReactSelect
                                            value={value}
                                            onChange={onChange}
                                            placeholder={intl.formatMessage({
                                                id: 'resources.factions',
                                                defaultMessage: 'Topic Tags, Factions'
                                            })}
                                            isMulti
                                            options={factions}
                                            closeMenuOnSelect={false}
                                            classNames={
                                                {
                                                    control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                                }
                                            }
                                            styles={{
                                                control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                                menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                                option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                            }}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.factions && errors.factions.message}
                                </FormErrorMessage>
                            </FormControl>}


                        <FormControl isInvalid={errors.location}>
                            <Controller
                                rules={{
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "resources.location",
                                                    defaultMessage: "Location"
                                                }
                                            )
                                        }
                                    )
                                }}
                                name="location"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    const vlu = value?.map(loc => theLocations.find(lc => [loc?.state, loc?.value].includes(lc.value)));
                                    return <ReactSelect
                                        value={vlu}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage({
                                            id: 'resources.location',
                                            defaultMessage: 'Location'
                                        })}
                                        isMulti
                                        options={theLocations}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                }
                                }
                            />
                            <FormErrorMessage>
                                {errors.location && errors.location.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.description}>
                            <Textarea
                                rows={5}
                                placeholder={
                                    intl.formatMessage({id: 'resources.description', defaultMessage: 'Description'})
                                }
                                background={bgColor}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}
                                {...register("description", {
                                    maxLength: {
                                        value: 200,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "resources.description",
                                                    defaultMessage: "Description"
                                                }),
                                                length: 200
                                            }
                                        )
                                    }
                                })}
                            />
                            <FormErrorMessage>
                                {errors.description && errors.description.message}
                            </FormErrorMessage>
                        </FormControl>

                        <span dir={context.isRTL ? 'rtl' : 'ltr'}>
                        {showRssPlatform && <Controller
                            control={control}
                            name="is_hashtag"
                            render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                <FormLabel htmlFor="is-hashtag">
                                    <FormattedMessage
                                        id="resources.isHashtag"
                                        defaultMessage="Is Hashtag?"
                                    />
                                </FormLabel>
                                <Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    isChecked={field.value}
                                    id="is-hashtag"
                                    size="lg"
                                    name="is_hashtag"
                                    colorScheme="green"
                                    variant="ghost"
                                />
                            </FormControl>)}
                        />}
                            <Controller
                                control={control}
                                name="status"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <FormLabel htmlFor="is-done">
                                        <FormattedMessage
                                            id="resources.status"
                                            defaultMessage="Status"
                                        />
                                    </FormLabel>
                                    <Switch
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        isChecked={field.value}
                                        id="status"
                                        size="lg"
                                        name="status"
                                        isDisabled={false}
                                        colorScheme="green"
                                        variant="ghost"
                                    />
                                </FormControl>)}
                            />
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage
                                    id="resources.close"
                                    defaultMessage="Close"
                                />
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={editable ? intl.formatMessage({
                                    id: 'resources.updating',
                                    defaultMessage: 'Updating...'
                                }) : intl.formatMessage({id: 'resources.creating', defaultMessage: 'Creating...'})}
                            >
                                {editable ? intl.formatMessage({
                                    id: 'resources.update',
                                    defaultMessage: 'Update'
                                }) : intl.formatMessage({id: 'resources.create', defaultMessage: 'Create'})}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
