const now = new Date();
const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const today = now.getTime() - midnight.getTime() > 0 ? ((now.getTime() - midnight.getTime()) / 1000).toFixed() : 0;

const configs = {
    country_code: 'tr',
    base_back_url: 'http://tr-raqib.ai:2650/api/v1/',
    // base_back_url: 'http://127.0.0.1:8000/api/v1/',
    // raqib_support_bot_url: 'tg://resolve?domain=tr_raqib_support_bot',
    raqib_support_bot_url: 'https://web.telegram.org/k/#@tr_raqib_support_bot',
    // time_locale: 'utc',
    // time_locale: 'Asia/Tehran',
    time_locale: Intl.DateTimeFormat().resolvedOptions().timeZone,   //Detect browser timezone and show as that zone
    target_location: {
        country: 'Turkiye',
        google_trends_country_code: 'TR',
    },
    roles: [
        {label: 'Superadmin', value: 'super_admin'},
        {label: 'Admin', value: 'admin'},
        {label: 'Viewer', value: 'viewer'},
        {label: 'Disabled', value: 'disabled'}
    ],
    projects: [
        {label: 'Map', value: 'RAQIB1'},
        {label: 'Monitoring', value: 'RAQIB6'},
        {label: 'News', value: 'RAQIB5'},
        {label: 'Enemy View', value: 'RAQIB7'},
        {label: 'Topics', value: 'RAQIB2'},
        {label: 'Image Trends', value: 'RAQIB3'},
        {label: 'Contextual Trends', value: 'RAQIB4'},
    ],
    locations: [
        {
            label: "International",
            value: "international",
            geo: {lat: 39.26896309692348, lon: 34.98062653898592},
            zoom: 4.4634811718476115
        },
        {
            label: "Adana",
            value: "adana",
            geo: {lat: 37.440848605342815, lon: 35.42000137016732},
            zoom: 9.18982747850914
        },
        {
            label: "Adıyaman",
            value: "adıyaman",
            geo: {lat: 37.77458372575263, lon: 38.34444648607051},
            zoom: 9.450635496983868
        },
        {
            label: "Afyon",
            value: "afyon",
            geo: {lat: 38.67219732287179, lon: 30.683503170286805},
            zoom: 8.93996804587934
        },
        {label: "Ağrı", value: "ağrı", geo: {lat: 39.59098694845663, lon: 43.31806140941119}, zoom: 9.293427243998721},
        {
            label: "Aksaray",
            value: "aksaray",
            geo: {lat: 38.33535377724561, lon: 33.82137735921913},
            zoom: 9.275651156246116
        },
        {
            label: "Amasya",
            value: "amasya",
            geo: {lat: 40.65866319727535, lon: 35.770452127520464},
            zoom: 9.428003383706615
        },
        {
            label: "Ankara",
            value: "ankara",
            geo: {lat: 39.789545578453584, lon: 32.631372258385554},
            zoom: 8.190739885778534
        },
        {
            label: "Antalya",
            value: "antalya",
            geo: {lat: 36.673346589405796, lon: 30.981313599152923},
            zoom: 8.421431366408804
        },
        {
            label: "Ardahan",
            value: "ardahan",
            geo: {lat: 41.09928623482317, lon: 42.852478495011766},
            zoom: 9.696005717517055
        },
        {
            label: "Artvin",
            value: "artvin",
            geo: {lat: 41.13844814402677, lon: 41.87618045029054},
            zoom: 9.443968598046835
        },
        {
            label: "Aydın",
            value: "aydın",
            geo: {lat: 37.76781217574823, lon: 27.980605333175077},
            zoom: 9.506785641338794
        },
        {
            label: "Balıkesir",
            value: "balıkesir",
            geo: {lat: 39.60644376523116, lon: 27.87665395083264},
            zoom: 8.972544761143832
        },
        {
            label: "Bartın",
            value: "bartın",
            geo: {lat: 41.53514060984796, lon: 32.464444391730915},
            zoom: 9.665248431764313
        },
        {
            label: "Batman",
            value: "batman",
            geo: {lat: 37.78814616912334, lon: 41.35569427661006},
            zoom: 9.890307638079301
        },
        {
            label: "Bayburt",
            value: "bayburt",
            geo: {lat: 40.247562910542314, lon: 40.24272290986346},
            zoom: 9.912536163972383
        },
        {
            label: "Bilecik",
            value: "bilecik",
            geo: {lat: 40.11427426591043, lon: 30.128535725683466},
            zoom: 9.409843185621725
        },
        {
            label: "Bingöl",
            value: "bingöl",
            geo: {lat: 39.00348149905505, lon: 40.76632990505991},
            zoom: 9.141177153376045
        },
        {
            label: "Bitlis",
            value: "bitlis",
            geo: {lat: 38.47091793847244, lon: 42.30347365567156},
            zoom: 9.041888459217931
        },
        {label: "Bolu", value: "bolu", geo: {lat: 40.62363951610431, lon: 31.855762141562433}, zoom: 9.29134088611867},
        {
            label: "Burdur",
            value: "burdur",
            geo: {lat: 37.450699671334895, lon: 30.072981962470497},
            zoom: 9.39786100643188
        },
        {
            label: "Bursa",
            value: "bursa",
            geo: {lat: 39.98042995504416, lon: 28.981581062216605},
            zoom: 9.229397805908201
        },
        {
            label: "Çanakkale",
            value: "çanakkale",
            geo: {lat: 40.02272460161694, lon: 26.886550580428207},
            zoom: 9.274174683722078
        },
        {
            label: "Çankırı",
            value: "çankırı",
            geo: {lat: 40.70357039298644, lon: 33.385887590048696},
            zoom: 8.830166587070451
        },
        {
            label: "Çorum",
            value: "çorum",
            geo: {lat: 40.517613503682234, lon: 34.66673313117813},
            zoom: 8.607360362000287
        },
        {
            label: "Denizli",
            value: "denizli",
            geo: {lat: 37.54348283981369, lon: 29.197061839056836},
            zoom: 9.429241715983375
        },
        {
            label: "Diyarbakır",
            value: "diyarbakır",
            geo: {lat: 38.052119659548936, lon: 40.410398649476775},
            zoom: 8.875740278972803
        },
        {
            label: "Düzce",
            value: "düzce",
            geo: {lat: 40.87333606789702, lon: 31.27320616753589},
            zoom: 10.03341872317723
        },
        {
            label: "Edirne",
            value: "edirne",
            geo: {lat: 40.91843725144819, lon: 26.53306795644495},
            zoom: 10.163671198887517
        },
        {
            label: "Elazığ",
            value: "elazığ",
            geo: {lat: 38.56354271890948, lon: 39.49931508228217},
            zoom: 9.735516335780542
        },
        {
            label: "Erzincan",
            value: "erzincan",
            geo: {lat: 39.68829615149583, lon: 39.20042966266709},
            zoom: 9.382277707593307
        },
        {
            label: "Erzurum",
            value: "erzurum",
            geo: {lat: 39.930984685989394, lon: 41.570903655750016},
            zoom: 8.701539447446725
        },
        {
            label: "Eskişehir",
            value: "eskişehir",
            geo: {lat: 39.61908515169851, lon: 31.0776821082058},
            zoom: 9.15990508667384
        },
        {
            label: "Gaziantep",
            value: "gaziantep",
            geo: {lat: 37.042559944838295, lon: 37.54087236394585},
            zoom: 10.026168716581743
        },
        {
            label: "Giresun",
            value: "giresun",
            geo: {lat: 40.49593994039727, lon: 38.54632875733384},
            zoom: 9.31545156212905
        },
        {
            label: "Gümüşhane",
            value: "gümüşhane",
            geo: {lat: 40.255494922118714, lon: 39.432500181958034},
            zoom: 9.31545156212905
        },
        {
            label: "Hakkari",
            value: "hakkari",
            geo: {lat: 37.45625691300055, lon: 44.118422059078064},
            zoom: 9.414892598080923
        },
        {
            label: "Hatay",
            value: "hatay",
            geo: {lat: 36.37432888793221, lon: 36.26679646010727},
            zoom: 8.903897071545991
        },
        {
            label: "Iğdır",
            value: "ığdır",
            geo: {lat: 39.89131608407529, lon: 43.94469263661198},
            zoom: 9.731902134129019
        },
        {
            label: "Isparta",
            value: "ısparta",
            geo: {lat: 37.88217671753793, lon: 30.96670312951099},
            zoom: 9.490807225594825
        },
        {
            label: "Istanbul",
            value: "istanbul",
            geo: {lat: 41.11449691217726, lon: 28.856856050667147},
            zoom: 9.096417605211904
        },
        {label: "Izmir", value: "izmir", geo: {lat: 38.2134228596245, lon: 27.39381804784008}, zoom: 8.729859865943608},
        {
            label: "K. Maraş",
            value: "k. maraş",
            geo: {lat: 37.89703040221505, lon: 37.03141562020349},
            zoom: 8.948087068247647
        },
        {
            label: "Karabük",
            value: "karabük",
            geo: {lat: 41.09883893016316, lon: 32.63596333120258},
            zoom: 9.610068444309793
        },
        {
            label: "Karaman",
            value: "karaman",
            geo: {lat: 37.13288332365554, lon: 33.31801608135928},
            zoom: 9.707035407209968
        },
        {label: "Kars", value: "kars", geo: {lat: 40.37894163142226, lon: 43.061303020496744}, zoom: 9.091439146303582},
        {
            label: "Kastamonu",
            value: "kastamonu",
            geo: {lat: 41.48262130590687, lon: 33.66362671431343},
            zoom: 8.841479903040492
        },
        {
            label: "Kayseri",
            value: "kayseri",
            geo: {lat: 38.66849354928783, lon: 35.88411930339684},
            zoom: 8.579267599231232
        },
        {
            label: "Kilis",
            value: "kilis",
            geo: {lat: 36.80133938407755, lon: 37.148099134469874},
            zoom: 10.723825554021092
        },
        {
            label: "Kırıkkale",
            value: "kırıkkale",
            geo: {lat: 39.7420887289136, lon: 33.701848321859764},
            zoom: 10.372901347926591
        },
        {
            label: "Kırklareli",
            value: "kırklareli",
            geo: {lat: 41.649653410453006, lon: 27.47370158958184},
            zoom: 9.592057606502253
        },
        {
            label: "Kırşehir",
            value: "kırşehir",
            geo: {lat: 39.29790603433591, lon: 34.163631460998545},
            zoom: 9.907267951048453
        },
        {
            label: "Kocaeli",
            value: "kocaeli",
            geo: {lat: 40.84025101587713, lon: 29.91164029227063},
            zoom: 9.874572951841335
        },
        {
            label: "Konya",
            value: "konya",
            geo: {lat: 37.93561680237331, lon: 32.57491770278298},
            zoom: 8.233823749283141
        },
        {
            label: "Kütahya",
            value: "kütahya",
            geo: {lat: 39.241505243416704, lon: 29.62100849720917},
            zoom: 8.800041798495865
        },
        {
            label: "Malatya",
            value: "malatya",
            geo: {lat: 38.522791371741086, lon: 38.04330123405076},
            zoom: 9.0088728818595
        },
        {
            label: "Manisa",
            value: "manisa",
            geo: {lat: 38.755783306437635, lon: 28.10979064573386},
            zoom: 9.184406302789684
        },
        {
            label: "Mardin",
            value: "mardin",
            geo: {lat: 37.35018519442503, lon: 40.907293922649814},
            zoom: 9.386185827267923
        },
        {
            label: "Mersin",
            value: "mersin",
            geo: {lat: 36.72902269441064, lon: 33.93008840324043},
            zoom: 9.013120261819498
        },
        {
            label: "Muğla",
            value: "muğla",
            geo: {lat: 37.0894192829381, lon: 28.448622937844696},
            zoom: 8.660740426854963
        },
        {label: "Muş", value: "muş", geo: {lat: 38.921510031419565, lon: 41.87886508209228}, zoom: 9.24813619427717},
        {
            label: "Nevşehir",
            value: "nevşehir",
            geo: {lat: 38.67719283225567, lon: 34.68157202950538},
            zoom: 9.174396043107937
        },
        {
            label: "Niğde",
            value: "niğde",
            geo: {lat: 37.97251936971176, lon: 34.78919036908889},
            zoom: 9.567192396128016
        },
        {label: "Ordu", value: "ordu", geo: {lat: 40.783121730332205, lon: 37.56422334880966}, zoom: 9.620991094005507},
        {
            label: "Osmaniye",
            value: "osmaniye",
            geo: {lat: 37.208345321572956, lon: 36.22533875081138},
            zoom: 10.170023975245067
        },
        {label: "Rize", value: "rize", geo: {lat: 40.90649446997235, lon: 40.905918407393884}, zoom: 9.553726151192427},
        {
            label: "Sakarya",
            value: "sakarya",
            geo: {lat: 40.710893635341044, lon: 30.595512252560336},
            zoom: 10.163415968786373
        },
        {
            label: "Samsun",
            value: "samsun",
            geo: {lat: 41.1633196479426, lon: 35.9734138583006},
            zoom: 8.998352093282993
        },
        {
            label: "Şanlıurfa",
            value: "şanlıurfa",
            geo: {lat: 37.17474630493066, lon: 39.13756988913125},
            zoom: 8.83316806183532
        },
        {label: "Siirt", value: "siirt", geo: {lat: 37.9165880668016, lon: 42.16118224123509}, zoom: 9.546802127743497},
        {label: "Sinop", value: "sinop", geo: {lat: 41.60792418196365, lon: 34.90407416531736}, zoom: 8.93814419954177},
        {
            label: "Şırnak",
            value: "şırnak",
            geo: {lat: 37.51697723955387, lon: 42.62580077695002},
            zoom: 8.092594775101764
        },
        {label: "Sivas", value: "sivas", geo: {lat: 39.5520124107049, lon: 37.23721015461166}, zoom: 8.641827813951965},
        {
            label: "Tekirdağ",
            value: "tekirdağ",
            geo: {lat: 41.0547032206311, lon: 27.33866442178398},
            zoom: 9.543798701958156
        },
        {
            label: "Tokat",
            value: "tokat",
            geo: {lat: 40.32004452838538, lon: 36.59995588249519},
            zoom: 9.201887895983559
        },
        {
            label: "Trabzon",
            value: "trabzon",
            geo: {lat: 40.809490911280186, lon: 39.815548323338184},
            zoom: 9.2435963548078
        },
        {
            label: "Tunceli",
            value: "tunceli",
            geo: {lat: 39.15571693347248, lon: 39.536445228373545},
            zoom: 9.105683989912402
        },
        {label: "Uşak", value: "uşak", geo: {lat: 38.48301644495595, lon: 29.35487291238563}, zoom: 9.383369130331277},
        {label: "Van", value: "van", geo: {lat: 38.29215281645367, lon: 43.65614966427052}, zoom: 8.872507325554679},
        {
            label: "Yalova",
            value: "yalova",
            geo: {lat: 40.58622210225516, lon: 29.123613498287714},
            zoom: 11.201021398908129
        },
        {
            label: "Yozgat",
            value: "yozgat",
            geo: {lat: 39.62220165992388, lon: 35.31916803420588},
            zoom: 8.758164701041204
        },
        {
            label: "Zonguldak",
            value: "zonguldak",
            geo: {lat: 41.23416775386753, lon: 31.856489225968957},
            zoom: 9.384335226226192
        },
    ],
    raqib1: {
        mapsOsmProvider: {
            token: "pk.eyJ1IjoibWZyYXNvdWxuZWphZCIsImEiOiJjbGM0cTR6aHkwcW8xM3ZwZ3hoYTNqNjY5In0.A7GD0TuHDQuzblYcGyh25g",
            attribution: {
                show: false,
                text: ""
            },
            colors: {
                'TRENDS24': [113, 128, 150],
                'TRENDSMAP': [113, 128, 150],
                'GOOGLE_TRENDS': [56, 161, 105],
                'TWITTER': [26, 32, 44],
                'RSS_INSTAGRAM': [229, 62, 62],
                'RSS_FACEBOOK': [43, 108, 176],
                'RSS_YOUTUBE': [128, 90, 213],
                'RSS_TIKTOK': [213, 63, 140],
                'RSS_TELEGRAM': [0, 136, 204],
                'RSS_OTHER': [214, 158, 46],
                'RSS_VK': [49, 151, 149],
                'RSS_OK': [0, 181, 216]
            },
            deckGl: {
                showCoordinates: false,
                controller: {
                    doubleClickZoom: false,
                    keyboard: false,
                    scrollZoom: false,
                    touchZoom: false,
                    dragPan: false
                },
                // lightStyle: 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json',
                lightStyle: 'mapbox://styles/mapbox/satellite-v9',
                // darkStyle: 'mapbox://styles/mapbox/dark-v9',
                darkStyle: 'mapbox://styles/mapbox/satellite-v9',
                terrain: {source: 'mapbox-dem', exaggeration: 1.5},
                scrollZoom: false,
                touchZoom: false,
                keyboard: false,
                doubleClickZoom: false,
                initial_states: {
                    longitude: 34.98062653898592,
                    latitude: 39.26896309692348,
                    zoom: 6.465480586885101,
                    pitch: 0,
                    bearing: 0
                }
            }
        },
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        sources: [
            {label: 'Trends24', value: 'TRENDS24'},
            {label: 'Trendsmap', value: 'TRENDSMAP'},
            {label: 'Google', value: 'GOOGLE_TRENDS'},
            {label: 'X', value: 'TWITTER'},
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'}
        ],
        mapMinFontSize: 15,
        mapMaxFontSize: 30,
        mapsSourcesVals: {
            // TRENDSMAP: 'TRENDSMAP',
            TRENDS24: 'TRENDS24',
            GOOGLE_TRENDS: 'GOOGLE_TRENDS',
            TWITTER: 'TWITTER',
            RSS_FACEBOOK: 'RSS_FACEBOOK',
            RSS_INSTAGRAM: 'RSS_INSTAGRAM',
            RSS_TIKTOK: 'RSS_TIKTOK',
            RSS_YOUTUBE: 'RSS_YOUTUBE',
            RSS_TELEGRAM: 'RSS_TELEGRAM',
            RSS_OTHER: 'RSS_OTHER',
            RSS_VK: 'RSS_VK',
            RSS_OK: 'RSS_OK'
        },
        mapsFilters: {
            timeranges: [
                {label: 'Last Hour', value: 3600},
                {label: 'Last 24 Hours', value: 86400},
                {label: 'Last 7 Days', value: 604800},
                {label: 'Last 30 Days', value: 2592000}
            ],
        }
    },
    raqib2: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib3: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib4: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib5: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib6: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        timeranges: [
            {label: '1 Hour', value: 3600},
            {label: '2 Hours', value: 7200},
            {label: '3 Hours', value: 10800},
            {label: '6 Hours', value: 21600},
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        social_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    raqib7: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        rss_platforms: [
            {label: 'Instagram', value: 'RSS_INSTAGRAM'},
            {label: 'Facebook', value: 'RSS_FACEBOOK'},
            {label: 'X', value: 'RSS_TWITTER'},
            {label: 'TikTok', value: 'RSS_TIKTOK'},
            {label: 'YouTube', value: 'RSS_YOUTUBE'},
            {label: 'Telegram', value: 'RSS_TELEGRAM'},
            {label: 'Web', value: 'RSS_OTHER'},
            {label: 'Vk', value: 'RSS_VK'},
            {label: 'Ok', value: 'RSS_OK'},
            {label: 'News', value: 'NEWS'},
        ],
        colors: {
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46],
            'RSS_VK': [49, 151, 149],
            'RSS_OK': [0, 181, 216],
            'NEWS': [153, 0, 76]
        }
    },
    articles: {
        // refresh page after this amount of time
        refresh_interval_default: 3600,
        // if true, enable auto refresh after refresh_interval_default seconds passed by default
        should_refresh_default: true,
        periods: [
            {label: 'Today', value: today},
            {label: '1 Day Ago', value: 86400},
            {label: '2 Days Ago', value: 172800},
            {label: '3 Days Ago', value: 259200},
            {label: '4 Days Ago', value: 345600},
            {label: '5 Days Ago', value: 432000},
            {label: '6 Days Ago', value: 518400},
            {label: '7 Days Ago', value: 604800}
        ],
        colors: {
            'GOOGLE_TRENDS': [56, 161, 105],
            'RSS_INSTAGRAM': [229, 62, 62],
            'RSS_TWITTER': [0, 0, 45],
            'RSS_FACEBOOK': [56, 88, 152],
            'RSS_TELEGRAM': [0, 136, 204],
            'RSS_YOUTUBE': [128, 90, 213],
            'RSS_TIKTOK': [213, 63, 140],
            'RSS_OTHER': [214, 158, 46]
        }
    },
    sources: [
        // {label: 'Trendsmap', value: 'TRENDSMAP'},
        {label: 'Trends24', value: 'TRENDS24'},
        {label: 'Google Trends', value: 'GOOGLE_TRENDS'},
        // {label: 'X Trends', value: 'TWITTER_TRENDS'},
        {label: 'Commoninja.com', value: 'COMMONINJA'},
        {label: 'Observer', value: 'NITTER'},
        {label: 'RSS', value: 'RSS'}
    ],
    rss_platforms: [
        {label: 'Instagram', value: 'RSS_INSTAGRAM'},
        {label: 'Facebook', value: 'RSS_FACEBOOK'},
        {label: 'X', value: 'RSS_TWITTER'},
        {label: 'Telegram', value: 'RSS_TELEGRAM'},
        {label: 'TikTok', value: 'RSS_TIKTOK'},
        {label: 'YouTube', value: 'RSS_YOUTUBE'},
        {label: 'Web', value: 'RSS_OTHER'},
        {label: 'Vk', value: 'RSS_VK'},
        {label: 'Ok', value: 'RSS_OK'}
    ]
}
export default configs;