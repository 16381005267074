import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
} from "@chakra-ui/react";
import {ArrowBackIcon, ArrowForwardIcon, ChevronDownIcon, HamburgerIcon} from "@chakra-ui/icons";

import {Outlet, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {useContext, useState} from "react";
import {FormattedMessage} from 'react-intl';
import {LocaleContext} from "../Theme/LocaleProvider";

export const NavBar = () => {
    const {isAuthenticated, logout, hasPermission} = useAuth();
    const context = useContext(LocaleContext);

    const navigate = useNavigate()
    const [xsMenu, setXsMenu] = useState(false);
    const [xsNews, setXsNews] = useState(false);
    const [xsEnemy, setXsEnemy] = useState(false);
    const [xsManage, setXsManage] = useState(false);

    const goTo = (to) => {
        setXsNews(false);
        setXsMenu(false);
        setXsManage(false);
        return navigate(to);
    }
    if (!isAuthenticated) {
        return <Outlet/>
    }
    const curPath = window.location.pathname;

    return (
        <Box minHeight="100vh">
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                padding="1rem"
                bg="gray.800"
                color="white"
            >
                <Text as="h2" fontSize={24} fontWeight="bold">
                    <FormattedMessage
                        id="NavBar.title"
                        description="App title"
                        defaultMessage="Raqib"
                    />
                </Text>

                {/* Show/hide menu button on mobile */}
                <Box display={{base: "block", md: "none"}}>
                    <Button variant="transparent" onClick={context.changeLanguage}>
                        <FormattedMessage
                            id={`language.${context.locale}`}
                            defaultMessage={context.locale}
                        />
                    </Button>
                    <IconButton
                        icon={<HamburgerIcon/>}
                        aria-label="Open menu"
                        variant="outline"
                        onClick={() => setXsMenu(true)}
                    />
                </Box>

                {/* Mobile Menu */}
                <Drawer
                    isOpen={xsMenu}
                    placement="right"
                    size={"full"}
                    onClose={() => setXsMenu(false)}
                >
                    <DrawerOverlay/>
                    <DrawerContent dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <DrawerCloseButton/>
                        <DrawerHeader>
                            <h1 dir={context.isRTL ? 'rtl' : 'ltr'}>
                                <FormattedMessage
                                    id="NavBar.menu"
                                    description="Menu"
                                    defaultMessage="Menu"
                                />
                            </h1>
                        </DrawerHeader>
                        <DrawerBody textAlign={'right'}>

                            {/* home */}
                            <Button
                                onClick={() => goTo("/")}
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.home"
                                    description="Home"
                                    defaultMessage="Home"
                                />
                            </Button>

                            {/* management */}
                            {(hasPermission('resource', 'fe_manage') || hasPermission('blacklists', 'fe_manage') || hasPermission('export', 'fe_manage') || hasPermission('news', 'fe_manage') || hasPermission('post_tag', 'fe_manage') || hasPermission('raqib7_post_tag', 'fe_manage') || hasPermission('raqib2_tag', 'fe_manage') || hasPermission('news_cat', 'fe_manage') || hasPermission('users', 'fe_manage')) &&
                                <Button
                                    onClick={() => setXsManage(true)}
                                    data-raqib={'raqib5'}
                                    variant="ghost"
                                    display="block"
                                    width="100%"
                                    textAlign={context.isRTL ? 'right' : 'left'}
                                >
                                    <FormattedMessage
                                        id="NavBar.management"
                                        description="Management"
                                        defaultMessage="Management"
                                    />
                                    <ChevronDownIcon/>
                                </Button>}
                            {(hasPermission('resource', 'fe_manage') || hasPermission('blacklists', 'fe_manage') || hasPermission('export', 'fe_manage') || hasPermission('news', 'fe_manage') || hasPermission('post_tag', 'fe_manage') || hasPermission('raqib7_post_tag', 'fe_manage') || hasPermission('raqib2_tag', 'fe_manage') || hasPermission('news_cat', 'fe_manage') || hasPermission('users', 'fe_manage')) &&
                                <Drawer
                                    isOpen={xsManage}
                                    placement="right"
                                    size={"full"}
                                    onClose={() => setXsManage(false)}
                                >
                                    <DrawerOverlay/>
                                    <DrawerContent textAlign={context.isRTL ? 'left' : 'right'}>
                                        <IconButton
                                            icon={context.isRTL ? <ArrowForwardIcon/> : <ArrowBackIcon/>}
                                            aria-label="Back to main menu"
                                            variant="ghost"
                                            position="absolute"
                                            top="1rem"
                                            {...(context.isRTL ? {right: "1rem"} : {left: "1rem"})}
                                            size={'xxl'}
                                            onClick={() => setXsManage(false)}
                                        />
                                        <DrawerHeader>
                                            <h1>
                                                <FormattedMessage
                                                    id="NavBar.management"
                                                    description="Management"
                                                    defaultMessage="Management"
                                                />
                                            </h1>
                                        </DrawerHeader>
                                        <DrawerBody>
                                            {hasPermission('resource', 'fe_manage') && <Button
                                                onClick={() => goTo("/resources")}
                                                data-raqib={'resources'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.resources"
                                                    description="Resources"
                                                    defaultMessage="Resources"
                                                />
                                            </Button>}
                                            {hasPermission('blacklists', 'fe_manage') && <Button
                                                onClick={() => goTo("/blacklists")}
                                                data-nazir={'blacklists'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage id="NavBar.blacklists"/>
                                            </Button>}
                                            {hasPermission('export', 'fe_manage') && <Button
                                                onClick={() => goTo("/exports")}
                                                data-raqib={'exports'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.exports"
                                                    description="Exports"
                                                    defaultMessage="Exports"
                                                />
                                            </Button>}
                                            {/*{hasPermission('export', 'fe_manage') && <Button*/}
                                            {/*    onClick={() => goTo("/reports")}*/}
                                            {/*    data-raqib={'reports'}*/}
                                            {/*    variant="ghost"*/}
                                            {/*    display="block"*/}
                                            {/*    width="100%"*/}
                                            {/*    textAlign={context.isRTL ? "left" : "right"}*/}
                                            {/*>*/}
                                            {/*    <FormattedMessage id="NavBar.reports"/>*/}
                                            {/*</Button>}*/}
                                            {hasPermission('news', 'fe_manage') && <Button
                                                onClick={() => goTo("/news_articles")}
                                                data-raqib={'news_articles'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.articles"
                                                    description="Articles"
                                                    defaultMessage="Articles"
                                                />
                                            </Button>}
                                            {hasPermission('post_tag', 'fe_manage') && <Button
                                                onClick={() => goTo("/post_tags")}
                                                data-raqib={'post_tags'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.postTags"
                                                    description="Post Tags"
                                                    defaultMessage="Post Tags"
                                                />
                                            </Button>}
                                            {hasPermission('raqib7_post_tag', 'fe_manage') && <Button
                                                onClick={() => goTo("/raqib7_post_tags")}
                                                data-raqib={'raqib7_post_tags'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.enemyPostTags"
                                                    description="Enemy Post Tags"
                                                    defaultMessage="Enemy Post Tags"
                                                />
                                            </Button>}
                                            {hasPermission('raqib2_tag', 'fe_manage') && <Button
                                                onClick={() => goTo("/raqib2_tags")}
                                                data-raqib={'raqib2_tags'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.topicsTags"
                                                    description="Topic Tags"
                                                    defaultMessage="Topic Tags"
                                                />
                                            </Button>}
                                            {hasPermission('news_cat', 'fe_manage') && <Button
                                                onClick={() => goTo("/news_cats")}
                                                data-raqib={'news_cats'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.articleCategories"
                                                    description="Article Categories"
                                                    defaultMessage="Article Categories"
                                                />
                                            </Button>}
                                            {hasPermission('users', 'fe_manage') && <Button
                                                onClick={() => goTo("/users")}
                                                data-raqib={'users'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.users"
                                                    description="Users"
                                                    defaultMessage="Users"
                                                />
                                            </Button>}
                                            {/*<Button*/}
                                            {/*    onClick={() => goTo("/apis")}*/}
                                            {/*    data-raqib={'apis'}*/}
                                            {/*    variant="ghost"*/}
                                            {/*    display="block"*/}
                                            {/*    width="100%"*/}
                                            {/*    textAlign={"left"}>APIs</Button>*/}
                                        </DrawerBody>
                                    </DrawerContent>
                                </Drawer>}

                            {/* maps */}
                            {hasPermission('raqib1', 'fe_view') && <Button
                                onClick={() => goTo("/raqib1")}
                                data-raqib="raqib6"
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.map"
                                    description="Map"
                                    defaultMessage="Map"
                                />
                            </Button>}

                            {/* monitoring */}
                            {hasPermission('raqib6', 'fe_view') && <Button
                                onClick={() => goTo("/raqib6")}
                                data-raqib="raqib6"
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.monitoring"
                                    description="Monitoring"
                                    defaultMessage="Monitoring"
                                />
                            </Button>}

                            {/* news */}
                            {(hasPermission('raqib5', 'fe_view_feed') || hasPermission('raqib5', 'fe_view_categorized')) &&
                                <Button
                                    onClick={() => setXsNews(true)}
                                    data-raqib={'raqib5'}
                                    variant="ghost"
                                    display="block"
                                    width="100%"
                                    textAlign={context.isRTL ? 'right' : 'left'}
                                >
                                    <FormattedMessage
                                        id="NavBar.news"
                                        description="News"
                                        defaultMessage="News"
                                    />
                                    <ChevronDownIcon/>
                                </Button>}
                            {(hasPermission('raqib5', 'fe_view_feed') || hasPermission('raqib5', 'fe_view_categorized')) &&
                                <Drawer
                                    isOpen={xsNews}
                                    placement="right"
                                    size={"full"}
                                    onClose={() => setXsNews(false)}
                                >
                                    <DrawerOverlay/>
                                    <DrawerContent textAlign={context.isRTL ? 'left' : 'right'}>
                                        <IconButton
                                            icon={context.isRTL ? <ArrowForwardIcon/> : <ArrowBackIcon/>}
                                            aria-label="Back to main menu"
                                            variant="ghost"
                                            position="absolute"
                                            top="1rem"
                                            {...(context.isRTL ? {right: "1rem"} : {left: "1rem"})}
                                            size={'xxl'}
                                            onClick={() => setXsNews(false)}
                                        />
                                        <DrawerHeader>
                                            <h1>
                                                <FormattedMessage
                                                    id="NavBar.news"
                                                    description="News"
                                                    defaultMessage="News"
                                                />
                                            </h1>
                                        </DrawerHeader>
                                        <DrawerBody>
                                            {hasPermission('raqib5', 'fe_view_feed') && <Button
                                                onClick={() => goTo('/raqib5')}
                                                data-raqib={'raqib5-news-feed'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.newsFeed"
                                                    description="News Feed"
                                                    defaultMessage="News Feed"
                                                />
                                            </Button>}
                                            {hasPermission('raqib5', 'fe_view_categorized') && <Button
                                                onClick={() => goTo('/raqib5/categorized')}
                                                data-raqib={'raqib5-categorized'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.categorizedNews"
                                                    description="Categorized News"
                                                    defaultMessage="Categorized News"
                                                />
                                            </Button>}
                                        </DrawerBody>
                                    </DrawerContent>
                                </Drawer>}

                            {/* enemies */}
                            {(hasPermission('raqib7', 'fe_view_feed') || hasPermission('raqib7', 'fe_view_categorized')) &&
                                <Button
                                    onClick={() => setXsEnemy(true)}
                                    data-raqib={'raqib7'}
                                    variant="ghost"
                                    display="block"
                                    width="100%"
                                    textAlign={context.isRTL ? 'right' : 'left'}
                                >
                                    <FormattedMessage
                                        id="NavBar.enemy"
                                        description="Enemy"
                                        defaultMessage="Enemy"
                                    />
                                    <ChevronDownIcon/>
                                </Button>}
                            {(hasPermission('raqib7', 'fe_view_feed') || hasPermission('raqib7', 'fe_view_categorized')) &&
                                <Drawer
                                    isOpen={xsEnemy}
                                    placement="right"
                                    size={"full"}
                                    onClose={() => setXsEnemy(false)}
                                >
                                    <DrawerOverlay/>
                                    <DrawerContent textAlign={context.isRTL ? 'left' : 'right'}>
                                        <IconButton
                                            icon={context.isRTL ? <ArrowForwardIcon/> : <ArrowBackIcon/>}
                                            aria-label="Back to main menu"
                                            variant="ghost"
                                            position="absolute"
                                            top="1rem"
                                            {...(context.isRTL ? {right: "1rem"} : {left: "1rem"})}
                                            size={'xxl'}
                                            onClick={() => setXsEnemy(false)}
                                        />
                                        <DrawerHeader>
                                            <h1>
                                                <FormattedMessage
                                                    id="NavBar.enemy"
                                                    description="Enemy"
                                                    defaultMessage="Enemy"
                                                />
                                            </h1>
                                        </DrawerHeader>
                                        <DrawerBody>
                                            {hasPermission('raqib7', 'fe_view_feed') && <Button
                                                onClick={() => goTo('/raqib7')}
                                                data-raqib={'raqib7-news-feed'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.enemyNewsFeed"
                                                    description="Enemy News Feed"
                                                    defaultMessage="Enemy News Feed"
                                                />
                                            </Button>}
                                            {hasPermission('raqib7', 'fe_view_categorized') && <Button
                                                onClick={() => goTo('/raqib7/categorized')}
                                                data-raqib={'raqib5-categorized'}
                                                variant="ghost"
                                                display="block"
                                                width="100%"
                                                textAlign={context.isRTL ? "left" : "right"}
                                            >
                                                <FormattedMessage
                                                    id="NavBar.categorizedEnemyNews"
                                                    description="Categorized Enemy News"
                                                    defaultMessage="Categorized Enemy News"
                                                />
                                            </Button>}
                                        </DrawerBody>
                                    </DrawerContent>
                                </Drawer>}

                            {/* articles */}
                            {hasPermission('news', 'fe_view') && <Button
                                onClick={() => goTo("/articles")}
                                data-raqib="articles"
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.articles"
                                    description="Articles"
                                    defaultMessage="Articles"
                                />
                            </Button>}

                            {/* topics */}
                            {hasPermission('raqib2', 'fe_view') && <Button
                                onClick={() => goTo("/raqib2")}
                                data-raqib="raqib2"
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.topics"
                                    description="Topics"
                                    defaultMessage="Topics"
                                />
                            </Button>}

                            {/* image trends */}
                            {hasPermission('raqib3', 'fe_view_list') && <Button
                                onClick={() => goTo("/raqib3")}
                                data-raqib="raqib3"
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.imageTrends"
                                    description="Image Trends"
                                    defaultMessage="Image Trends"
                                />
                            </Button>}

                            {/* context trends */}
                            {hasPermission('raqib4', 'fe_view_list') && <Button
                                onClick={() => goTo("/raqib4")}
                                data-raqib="raqib4"
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.contextTrends"
                                    description="Context Trends"
                                    defaultMessage="Context Trends"
                                />
                            </Button>}

                            {/* logout */}
                            <Button
                                onClick={logout}
                                variant="ghost"
                                display="block"
                                width="100%"
                                textAlign={context.isRTL ? 'right' : 'left'}
                            >
                                <FormattedMessage
                                    id="NavBar.logout"
                                    description="Logout"
                                    defaultMessage="Logout"
                                />
                            </Button>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                {/* Desktop menu */}
                <Stack direction="row" align="center" spacing={2} display={{base: "none", md: "block"}}>

                    {/* home */}
                    <Button
                        onClick={() => goTo("/")}
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath === '/' ? 'facebook' : 'gray.800'}
                        color="white"
                    >
                        <FormattedMessage
                            id="NavBar.home"
                            description="Home"
                            defaultMessage="Home"
                        />
                    </Button>

                    {/* management */}
                    {(hasPermission('resource', 'fe_manage') || hasPermission('blacklists', 'fe_manage') || hasPermission('export', 'fe_manage') || hasPermission('news', 'fe_manage') || hasPermission('post_tag', 'fe_manage') || hasPermission('raqib7_post_tag', 'fe_manage') || hasPermission('raqib2_tag', 'fe_manage') || hasPermission('news_cat', 'fe_manage') || hasPermission('users', 'fe_manage')) &&
                        <Menu placement="bottom-end">
                            <MenuButton as={Button} marginRight="1rem"
                                        colorScheme={curPath.startsWith('/resources') || curPath.startsWith('/blacklists') || curPath.startsWith('/exports') || curPath.startsWith('/news_articles') || curPath.startsWith('/post_tags') || curPath.startsWith('/raqib7_post_tags') || curPath.startsWith('/news_cats') || curPath.startsWith('/users') || curPath.startsWith('/raqib2_tags') ? 'facebook' : 'gray.800'}
                                        color="white">
                                <FormattedMessage
                                    id="NavBar.management"
                                    description="Management"
                                    defaultMessage="Management"
                                />
                                <ChevronDownIcon/>
                            </MenuButton>
                            <MenuList color="black" style={{borderColor: "gray"}} bgColor={"gray.800"}>

                                {hasPermission('resource', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/resources")} bgColor={"gray.800"}
                                              color="white">
                                        <FormattedMessage
                                            id="NavBar.resources"
                                            description="Resources"
                                            defaultMessage="Resources"
                                        />
                                    </MenuItem>}
                                {hasPermission('blacklists', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/blacklists")} bgColor={"gray.800"}
                                              color="white">
                                        <FormattedMessage id="NavBar.blacklists"/>
                                    </MenuItem>}
                                {hasPermission('export', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/exports")} bgColor={"gray.800"}
                                              color="white">
                                        <FormattedMessage
                                            id="NavBar.exports"
                                            description="Exports"
                                            defaultMessage="Exports"
                                        />
                                    </MenuItem>}
                                {/*{hasPermission('export', 'fe_manage') &&*/}
                                {/*    <MenuItem onClick={() => goTo("/reports")} bgColor={"gray.800"}*/}
                                {/*              color="white">*/}
                                {/*        <FormattedMessage id="NavBar.reports"/>*/}
                                {/*    </MenuItem>}*/}
                                {hasPermission('news', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/news_articles")} bgColor={"gray.800"}
                                              color="white">
                                        <FormattedMessage
                                            id="NavBar.articles"
                                            description="Articles"
                                            defaultMessage="Articles"
                                        />
                                    </MenuItem>}
                                {hasPermission('post_tag', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/post_tags")} bgColor={"gray.800"} color="white">
                                        <FormattedMessage
                                            id="NavBar.postTags"
                                            description="Post Tags"
                                            defaultMessage="Post Tags"
                                        />
                                    </MenuItem>}
                                {hasPermission('raqib7_post_tag', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/raqib7_post_tags")} bgColor={"gray.800"}
                                              color="white">
                                        <FormattedMessage
                                            id="NavBar.enemyPostTags"
                                            description="Enemy Post Tags"
                                            defaultMessage="Enemy Post Tags"
                                        />
                                    </MenuItem>}
                                {hasPermission('raqib2_tag', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/raqib2_tags")} bgColor={"gray.800"} color="white">
                                        <FormattedMessage
                                            id="NavBar.topicsTags"
                                            description="Topic Tags"
                                            defaultMessage="Topic Tags"
                                        />
                                    </MenuItem>}
                                {hasPermission('news_cat', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/news_cats")} bgColor={"gray.800"} color="white">
                                        <FormattedMessage
                                            id="NavBar.articleCategories"
                                            description="Article Categories"
                                            defaultMessage="Article Categories"
                                        />
                                    </MenuItem>}
                                {hasPermission('users', 'fe_manage') &&
                                    <MenuItem onClick={() => goTo("/users")} bgColor={"gray.800"}
                                              color="white">
                                        <FormattedMessage
                                            id="NavBar.users"
                                            description="Users"
                                            defaultMessage="Users"
                                        />
                                    </MenuItem>}
                                {/*<MenuItem onClick={() => goTo("/apis")} bgColor={"gray.800"} color="white">APIs</MenuItem>*/}
                            </MenuList>
                        </Menu>}

                    {/* maps */}
                    {hasPermission('raqib1', 'fe_view') && <Button
                        onClick={() => goTo("/raqib1")}
                        data-raqib="raqib1"
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath === '/raqib1' ? 'facebook' : 'gray.800'}
                        color="white">
                        <FormattedMessage
                            id="NavBar.map"
                            description="Map"
                            defaultMessage="Map"
                        />
                    </Button>}

                    {/* monitoring */}
                    {hasPermission('raqib6', 'fe_view') && <Button
                        onClick={() => goTo("/raqib6")}
                        data-raqib="raqib6"
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath === '/raqib6' ? 'facebook' : 'gray.800'}
                        color="white">
                        <FormattedMessage
                            id="NavBar.monitoring"
                            description="Monitoring"
                            defaultMessage="Monitoring"
                        />
                    </Button>}

                    {/* news */}
                    {(hasPermission('raqib5', 'fe_view_feed') || hasPermission('raqib5', 'fe_view_categorized')) &&
                        <Menu placement="bottom-end">
                            <MenuButton
                                as={Button}
                                data-raqib={'raqib5'}
                                marginRight="1rem"
                                colorScheme={curPath.startsWith('/raqib5') ? 'facebook' : 'gray.800'}
                                color="white">
                                <FormattedMessage
                                    id="NavBar.news"
                                    description="News"
                                    defaultMessage="News"
                                />
                                <ChevronDownIcon/>
                            </MenuButton>

                            <MenuList color="black" style={{borderColor: "gray"}} bgColor={"gray.800"}>
                                {hasPermission('raqib5', 'fe_view_feed') && <MenuItem
                                    onClick={() => goTo('/raqib5')}
                                    data-raqib={"raqib5"}
                                    bgColor={"gray.800"}
                                    color="white">
                                    <FormattedMessage
                                        id="NavBar.newsFeed"
                                        description="News Feed"
                                        defaultMessage="News Feed"
                                    />
                                </MenuItem>}

                                {hasPermission('raqib5', 'fe_view_categorized') && <MenuItem
                                    onClick={() => goTo('/raqib5/categorized')}
                                    data-raqib={"raqib5-categorized"}
                                    bgColor={"gray.800"}
                                    color="white">
                                    <FormattedMessage
                                        id="NavBar.categorizedNews"
                                        description="Categorized News"
                                        defaultMessage="Categorized News"
                                    />
                                </MenuItem>}
                            </MenuList>
                        </Menu>}

                    {/* enemies */}
                    {(hasPermission('raqib7', 'fe_view_feed') || hasPermission('raqib7', 'fe_view_categorized')) &&
                        <Menu placement="bottom-end">
                            <MenuButton
                                as={Button}
                                data-raqib={'raqib7'}
                                marginRight="1rem"
                                colorScheme={curPath === '/raqib7' ? 'facebook' : 'gray.800'}
                                color="white">
                                <FormattedMessage
                                    id="NavBar.enemy"
                                    description="Enemy"
                                    defaultMessage="Enemy"
                                />
                                <ChevronDownIcon/>
                            </MenuButton>

                            <MenuList color="black" style={{borderColor: "gray"}} bgColor={"gray.800"}>
                                {hasPermission('raqib7', 'fe_view_feed') && <MenuItem
                                    onClick={() => goTo('/raqib7')}
                                    data-raqib={"raqib7"}
                                    bgColor={"gray.800"}
                                    color="white">
                                    <FormattedMessage
                                        id="NavBar.enemyNewsFeed"
                                        description="Enemy News Feed"
                                        defaultMessage="Enemy News Feed"
                                    />
                                </MenuItem>}

                                {hasPermission('raqib7', 'fe_view_categorized') && <MenuItem
                                    onClick={() => goTo('/raqib7/categorized')}
                                    data-raqib={"raqib7-categorized"}
                                    bgColor={"gray.800"}
                                    color="white">
                                    <FormattedMessage
                                        id="NavBar.categorizedEnemyNews"
                                        description="Categorized Enemy News"
                                        defaultMessage="Categorized Enemy News"
                                    />
                                </MenuItem>}
                            </MenuList>
                        </Menu>}

                    {/* articles */}
                    {hasPermission('news', 'fe_view') && <Button
                        onClick={() => goTo("/articles")}
                        data-raqib="articles"
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath.startsWith('/articles') ? 'facebook' : 'gray.800'}
                        color="white">
                        <FormattedMessage
                            id="NavBar.articles"
                            description="Articles"
                            defaultMessage="Articles"
                        />
                    </Button>}

                    {/* topics */}
                    {hasPermission('raqib2', 'fe_view') && <Button
                        onClick={() => goTo("/raqib2")}
                        data-raqib="raqib2"
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath === '/raqib2' || curPath.startsWith('/raqib2/') ? 'facebook' : 'gray.800'}
                        color="white">
                        <FormattedMessage
                            id="NavBar.topics"
                            description="Topics"
                            defaultMessage="Topics"
                        />
                    </Button>}

                    {/* image trends */}
                    {hasPermission('raqib3', 'fe_view_list') && <Button
                        onClick={() => goTo("/raqib3")}
                        data-raqib="raqib3"
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath.startsWith('/raqib3') ? 'facebook' : 'gray.800'}
                        color="white">
                        <FormattedMessage
                            id="NavBar.imageTrends"
                            description="Image Trends"
                            defaultMessage="Image Trends"
                        />
                    </Button>}

                    {/* context trends */}
                    {hasPermission('raqib4', 'fe_view_list') && <Button
                        onClick={() => goTo("/raqib4")}
                        data-raqib="raqib4"
                        // variant="ghost"
                        marginRight="1rem"
                        colorScheme={curPath.startsWith('/raqib4') ? 'facebook' : 'gray.800'}
                        color="white">
                        <FormattedMessage
                            id="NavBar.contextTrends"
                            description="Context Trends"
                            defaultMessage="Context Trends"
                        />
                    </Button>}
                </Stack>

                <Stack direction="row" align="center" spacing={4} display={{base: "none", md: "block"}}>

                    {/* language change */}
                    <Button variant="transparent" onClick={context.changeLanguage}>
                        <FormattedMessage
                            id={`language.${context.locale}`}
                            defaultMessage={context.locale}
                        />
                    </Button>

                    {/* logout */}
                    <Button
                        onClick={logout}
                        colorScheme={"gray.800"}
                        color="white">
                        <FormattedMessage
                            id="NavBar.logout"
                            description="Logout"
                            defaultMessage="Logout"
                        />
                    </Button>
                </Stack>
            </Flex>
            <Outlet/>
        </Box>
    );
};
