import {Box, Card, CardBody, CardHeader, Center, Flex, Heading, Image, Link, Text, Tooltip} from "@chakra-ui/react";
import {configs} from "../../Configs";
import {useEffect, useRef, useState} from "react";
import HTMLParser from "../../utils/HtmlParser";
import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useIntl} from "react-intl";

export const PostRow = ({
                            image = null,
                            title,
                            description,
                            published_at,
                            source,
                            link,
                            refr,
                            id,
                            tags,
                            resource,
                            ...rest
                        }) => {
    const intl = useIntl();
    const titleRef = useRef(null);
    const textRef = useRef(null);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        setTextDirection(textRef.current, description.replace(/<[^>]*>?/gm, ''));
    }, [title, description]);

    const thePlatform = configs.raqib7.rss_platforms.find(item => item.value === source);

    return (<Card ref={refr} mb={1} style={{overflow: 'hidden'}}
                  onMouseEnter={() => setShowMore(true)}
                  onMouseLeave={() => setShowMore(false)}
        >
            <Box textAlign="center" style={{backgroundColor: `rgb(${configs.raqib7.colors[source]})`}}
                 color={'white'}
                 borderTopRightRadius='lg'
                 borderTopLeftRadius='lg'
            >
                <Link href={resource.origin_url || link} isExternal>
                    [{resource.title}] - {intl.formatMessage({
                    id: "raqib7.on",
                    defaultMessage: "On"
                })} {intl.formatMessage({
                    id: `raqib7.${thePlatform.value}`,
                    defaultMessage: thePlatform.label
                })}
                </Link>
            </Box>
            <Flex>
                {image && showMore && <Box flex="1" alignItems={"center"}>
                    <Center h={'100%'}>
                        <Image
                            w={'100%'}
                            src={image}
                            alt={title}
                        />
                    </Center>
                </Box>}
                <Box flex={image && showMore ? 3 : 1}>
                    <CardHeader p={2}>
                        <Link href={link} isExternal>
                            <Heading size='sm' fontFamily={"Cairo"} ref={titleRef}>{title}</Heading>
                        </Link>
                    </CardHeader>
                    <CardBody textAlign={'left'} hidden={!showMore}>
                        <Box ref={textRef} noOfLines={2} className={"accented-scrollbar"} overflowY={"scroll"}
                             fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'}>
                            <HTMLParser htmlContent={description}/>
                        </Box>
                    </CardBody>

                    {showMore &&
                        <Text textAlign={"right"} p={2} color='gray.500' fontSize='sm'>
                            <Tooltip label={gregorianJalaliDTConversion(published_at)} aria-label='datetime-conversion'>
                                {dtToLocaleString(published_at)}
                            </Tooltip>
                        </Text>}
                </Box>
            </Flex>
        </Card>
    );
}