import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {FaRegStar, FaStar} from "react-icons/fa";

import {LocaleContext} from '../Theme/LocaleProvider';
import {useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {configs} from "../../Configs";
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CloseIcon} from '@chakra-ui/icons'
import {dtToLocaleString} from '../../utils/helpers';
import useAxios from "../../hooks/useAxois";
import {FormattedMessage, useIntl} from 'react-intl';
import AsyncSelect from 'react-select/async';

export const AddExportModal = ({
                                   onSuccess = () => {
                                   },
                                   ...rest
                               }) => {
    const context = useContext(LocaleContext);
    const intl = useIntl();
    const axiosInstance = useAxios();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast();

    const defaultValues = {is_csv: true};

    const {
        handleSubmit,
        register,
        control,
        formState: {errors, isSubmitting}
    } = useForm({defaultValues: {...defaultValues}});


    const [since, setSince] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [until, setUntil] = useState(new Date());
    const [factions, setFactions] = useState([]);
    const [postTags, setPostTags] = useState([]);

    useEffect(() => {
        // fetch factions list
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));

        // fetch post_tags list
        axiosInstance.get('/post_tag').then((data) => setPostTags(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    const formatDatetime = (date) => {
        if (!date) {
            return '';
        }
        return dtToLocaleString(date, undefined, configs.time_locale)
    }

    const fetchResources = async (inputValue) => {
        // fetch resources list
        const response = await axiosInstance.get('/resource', {params: {title: inputValue}});
        return response?.data?.map(item => ({
            value: item.resource_id,
            label: item.title
        }));
    };

    const onSubmit = async (values) => {
        values.platforms = values.platforms?.map((platform) => platform.value);
        values.resources = values.resources?.map((resource) => resource.value);
        values.factions = values.factions?.map((faction) => faction.value);
        values.post_tags = values.post_tags?.map((post_tag) => post_tag.value);

        if (since) {
            values.since = since.toISOString()
        }

        if (until) {
            values.until = until.toISOString()
        }

        try {
            await axiosInstance.post(`/export/create/`, values);
            toast({
                title: intl.formatMessage({id: 'exports.submitted', defaultMessage: 'Export request submitted'}),
                status: "success",
                isClosable: true,
                diration: 1500,
            });
            onSuccess();
            onClose();
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    };

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        transitionProperty: 'var(--chakra-transition-property-common)',
        transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-lg)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        minHeight: 'var(--chakra-sizes-12)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '0px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        marginTop: 'var(--chakra-space-6)',
        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: useColorModeValue("var(--chakra-colors-gray-800)", "var(--chakra-colors-gray-300)"),
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    return (<Box {...rest}>
        <Button mt={2} size={'sm'} onClick={onOpen} colorScheme={'green'}>
            <FormattedMessage
                id="exports.addExport"
                defaultMessage="New Export"
            />
        </Button>
        <Modal
            closeOnOverlayClick={false}
            size={{base: "full", md: "xl"}}
            onClose={onClose}
            isOpen={isOpen}
            // isCentered
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader dir={context.isRTL ? 'rtl' : 'ltr'}>
                        <Heading fontSize='2xl' textAlign={'start'}>
                            <FormattedMessage
                                id="exports.requestAnExport"
                                defaultMessage="Request an export"
                            />
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isInvalid={errors.title}>
                            <Input
                                placeholder={
                                    intl.formatMessage({id: 'exports.exportTitle', defaultMessage: 'Title'})
                                }
                                background={useColorModeValue("gray.300", "gray.600")}
                                type="text"
                                variant="filled"
                                size="lg"
                                mt={6}

                                {...register("title", {
                                    required: intl.formatMessage(
                                        {
                                            id: "validation.fieldRequired",
                                            defaultMessage: "{field} is required"
                                        },
                                        {
                                            field: intl.formatMessage(
                                                {
                                                    id: "exports.exportTitle",
                                                    defaultMessage: "Title"
                                                }
                                            )
                                        }
                                    ),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMinLen",
                                                defaultMessage: "{field} must be at least {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "exports.exportTitle",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 1
                                            }
                                        )
                                    },
                                    maxLength: {
                                        value: 128,
                                        message: intl.formatMessage(
                                            {
                                                id: "validation.fieldMaxLen",
                                                defaultMessage: "{field} must be at most {length} characters"
                                            },
                                            {
                                                field: intl.formatMessage({
                                                    id: "exports.exportTitle",
                                                    defaultMessage: "Title"
                                                }),
                                                length: 128
                                            }
                                        )
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.title && errors.title.message}
                            </FormErrorMessage>
                        </FormControl>

                        <Flex alignItems={"center"}>
                            <Controller
                                control={control}
                                name="is_csv"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <RadioGroup id="is-csv" value={`${field.value}`}
                                                onChange={(e) => field.onChange(e)}>
                                        <Stack direction='row'>
                                            <Radio name="is_csv" value='true' colorScheme="green">CSV</Radio>
                                            <Radio name="is_csv" value='false' colorScheme="green">Word</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>)
                                }
                            />
                            <Controller
                                control={control}
                                name="has_star"
                                render={({field}) => (<FormControl mt={6} display="flex" alignItems="center">
                                    <Stack direction='row'>
                                        <IconButton
                                            size={'md'}
                                            variant={'outline'}
                                            icon={
                                                field.value ?
                                                    <FaStar style={{color: 'orange', display: 'inline-block'}}
                                                            cursor={'pointer'}/> :
                                                    <FaRegStar style={{color: 'orange', display: 'inline-block'}}
                                                               cursor={'pointer'}/>
                                            }
                                            onClick={() => field.onChange(!field.value)}
                                        />
                                    </Stack>
                                    <Text ms={2} cursor={'pointer'} onClick={() => field.onChange(!field.value)}>{intl.formatMessage({id: "exports.hasStar"})}</Text>
                                </FormControl>)
                                }
                            />
                        </Flex>

                        <Flex alignItems="center" id="date-picker-portal">
                            <FormControl isInvalid={errors.since} mr={1}>
                                <DatePicker
                                    selected={since}
                                    onChange={(date) => setSince(date)}
                                    placeholderText={intl.formatMessage({
                                        id: 'exports.exportSince',
                                        defaultMessage: 'Since'
                                    })}
                                    showTimeSelect
                                    portalId="date-picker-portal"
                                    customInput={
                                        // Custom input component
                                        <Flex>
                                            <Input
                                                value={since ? formatDatetime(since) : ''}
                                                placeholder={
                                                    intl.formatMessage({
                                                        id: 'exports.exportSince',
                                                        defaultMessage: 'Since'
                                                    })
                                                }
                                                background={useColorModeValue("gray.300", "gray.600")}
                                                type="text"
                                                variant="filled"
                                                size="lg"
                                                mt={6}
                                                borderRightRadius={0}
                                                onChange={() => {}} // Add a no-op onChange handler to prevent warnings
                                            />
                                            <IconButton mt={6} h={12} borderLeftRadius={0} aria-label="clear"
                                                        icon={<CloseIcon/>} onClick={() => setSince(null)}/>
                                        </Flex>
                                    }
                                />

                                <FormErrorMessage>
                                    {errors.since && errors.since.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.until} ml={1}>
                                <DatePicker
                                    selected={until}
                                    onChange={(date) => setUntil(date)}
                                    placeholderText={intl.formatMessage({
                                        id: 'exports.exportUntil',
                                        defaultMessage: 'Until'
                                    })}
                                    showTimeSelect
                                    portalId="date-picker-portal"
                                    customInput={
                                        // Custom input component
                                        <Flex>
                                            <Input
                                                value={until ? formatDatetime(until) : ''}
                                                placeholder={intl.formatMessage({
                                                    id: 'exports.exportUntil',
                                                    defaultMessage: 'Until'
                                                })}
                                                background={useColorModeValue("gray.300", "gray.600")}
                                                type="text"
                                                variant="filled"
                                                size="lg"
                                                mt={6}
                                                borderRightRadius={0}
                                                onChange={() => {}} // Add a no-op onChange handler to prevent warnings
                                            />
                                            <IconButton mt={6} h={12} borderLeftRadius={0} aria-label="clear until"
                                                        icon={<CloseIcon/>} onClick={() => setUntil(null)}/>
                                        </Flex>
                                    }
                                />

                                <FormErrorMessage>
                                    {errors.until && errors.until.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <FormControl isInvalid={errors.platforms}>
                            <Controller
                                name="platforms"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        value={value}
                                        onChange={onChange}
                                        placeholder={
                                            intl.formatMessage({
                                                id: 'exports.exportPlatforms',
                                                defaultMessage: 'Platforms'
                                            })
                                        }
                                        isMulti
                                        options={configs.rss_platforms.map((rss_platform) => ({
                                            value: rss_platform.value,
                                            label: rss_platform.label
                                        }))}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.platforms && errors.platforms.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.resources}>
                            <Controller
                                name="resources"
                                control={control}
                                render={({field}) => (
                                    <AsyncSelect
                                        {...field}
                                        onChange={(option) => field.onChange(option)}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                fetchResources(inputValue).then(options => {
                                                    callback(options);
                                                });
                                            }
                                        }
                                        cacheOptions
                                        defaultOptions
                                        isMulti
                                        closeMenuOnSelect={false}
                                        placeholder={intl.formatMessage({id: 'exports.exportResources'})}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.resources && errors.resources.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.factions}>
                            <Controller
                                name="factions"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        value={value}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage({
                                            id: 'exports.exportTopicsFactions',
                                            defaultMessage: 'Topics, Factions'
                                        })}
                                        isMulti
                                        options={factions}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.factions && errors.factions.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.post_tags}>
                            <Controller
                                name="post_tags"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactSelect
                                        value={value}
                                        onChange={onChange}
                                        placeholder={intl.formatMessage({
                                            id: 'exports.exportPostTags',
                                            defaultMessage: 'Post Tags'
                                        })}
                                        isMulti
                                        options={postTags}
                                        closeMenuOnSelect={false}
                                        classNames={
                                            {
                                                control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                            }
                                        }
                                        styles={{
                                            control: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelect}),
                                            menu: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                            option: (baseStyles, state) => ({...baseStyles, ...cstmMutliSelectOptions})
                                        }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.post_tags && errors.post_tags.message}
                            </FormErrorMessage>
                        </FormControl>


                    </ModalBody>
                    <ModalFooter>
                        <Stack direction="row" spacing={4}>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <FormattedMessage
                                    id="exports.close"
                                    defaultMessage="Close"
                                />
                            </Button>
                            <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                                loadingText={intl.formatMessage({
                                    id: 'exports.creating',
                                    defaultMessage: 'Submitting...'
                                })}
                            >
                                <FormattedMessage
                                    id="exports.create"
                                    defaultMessage="Submit Request"
                                />
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    </Box>);
};
