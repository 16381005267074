import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {LocaleContext} from './LocaleProvider';
import {useContext} from "react";

const RtlProvider = ({children}) => {
    const context = useContext(LocaleContext);
    const direction = context.isRTL ? 'rtl' : 'ltr';

    // change toast notifications direction and font
    const fontFamily = 'BlinkMacSystemFont, Cairo, IRANSans, sans-serif';
    const toastAlertStyles = {
        container: {
            direction: direction,
        },
        title: {
            fontFamily: fontFamily
        },
    };
    const baseStyle = {
        baseStyle: {
            fontFamily: fontFamily
        }
    }
    const components = {
        Badge: baseStyle,
        Input: baseStyle,
        Select: baseStyle,
        Tooltip: baseStyle,
        Textarea: baseStyle,
        Button: baseStyle,
        Label: baseStyle,
        Radio: baseStyle,
        Alert: {
            variants: {
                subtle: toastAlertStyles,
                solid: toastAlertStyles,
                'left-accent': toastAlertStyles,
                'top-accent': toastAlertStyles,
            }
        },
    };

    return <ChakraProvider theme={extendTheme({direction, components})}>{children}</ChakraProvider>
}

export default RtlProvider;