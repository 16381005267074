import {Post} from './post';
import {Box, Spinner, Text} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from "react";
import useAxios from "../../hooks/useAxois";
import axios from "axios";
import {FormattedMessage} from 'react-intl';

export const Raqib2PostsColumn = ({faction, factionsCount, shouldRefresh, refreshInterval}) => {
    const axiosInstance = useAxios();
    // pagination related stuff
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastElmId, setLastElmId] = useState(null);
    const [error, setError] = useState(false);
    const [pageId, setPageId] = useState(null);
    const [nextPageId, setNextPageId] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const observer = useRef();

    useEffect(() => {
        if (!faction) return;
        setLastElmId(null);
        setIsLoading(true);
        setError(false);
        let cancel;
        axiosInstance.get("/raqib2/", {
            params: {faction_ids: faction.value, after: pageId},
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((res) => setData(prev => {
            const theData = [...prev, ...res.data];

            // uniqify data combination
            let itteratedKeys = [];
            let newData = [];
            theData.map(pst => {
                if (!itteratedKeys.includes(`${pst.faction.tag_id.tag_id}:${pst.source}:${pst.id}`)) {
                    itteratedKeys.push(`${pst.faction.tag_id.tag_id}:${pst.source}:${pst.id}`);
                    newData.push(pst);
                }
            })
            // the nextPageId is determined by least sort (desc)
            let sortedData = theData;
            sortedData.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort));
            setLastElmId(sortedData.length ? `${sortedData[0].faction.tag_id}:${sortedData[0].source}:${sortedData[0].id}` : null);
            setNextPageId(sortedData.length ? sortedData[0].sort : null)
            setIsLoading(false);
            setError(false);
            return newData;
        })).catch((error) => {
            if (axios.isCancel(error)) return;
            setIsLoading(false);
            setError(true);
            console.error(error);
        });
        return () => cancel();
    }, [faction, pageId, forceRefresh])

    const lastPostElmRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextPageId) {
                setPageId(nextPageId)
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, nextPageId])


    useEffect(() => {

        if (!shouldRefresh) {
            return
        }

        const theInterval = setInterval(() => {
            setData([]);
            setIsLoading(false);
            setLastElmId(null);
            setError(false);
            setNextPageId(null);
            setPageId(null);
            setForceRefresh(prevForceRefresh => !prevForceRefresh);
        }, refreshInterval * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    return (
        <Box
            maxW={{md: '25%'}}
            w={{base: '95vw', md: `calc((100% - 26px) / ${factionsCount} );`}}
            mx={{base: '15px', md: 'auto'}}
            align="center"
            wrap="wrap"
            justify="space-between"
        >
            <Box
                w={'100%'}
                mx={"auto"}
                mb={5}
                textAlign={'center'}>
                <Text fontFamily={'Cairo'} fontSize={18} fontWeight="bold">{faction.label}</Text>
            </Box>

            {data && data.map(pst => {
                if (`${faction.value}:${pst.source}:${pst.id}` === lastElmId) {
                    return <Post
                        key={`${pst.faction.tag_id}:${pst.source}:${pst.id}`}
                        {...pst}
                        refr={lastPostElmRef}
                    />
                } else {
                    return <Post key={`${pst.faction.tag_id}:${pst.source}:${pst.id}`} {...pst}/>
                }
            })}
            {data && !data.filter(pst => faction.value === pst.faction.tag_id).length && !isLoading &&
                <Text >
                    <FormattedMessage
                        id="responses.noData"
                        defaultMessage="No Data"
                    />
                </Text>}
            {!isLoading && error && <Text >
                <FormattedMessage
                    id="responses.connectionFailed"
                    defaultMessage="Connection Failed"
                />
            </Text>}
            {isLoading && <Spinner mt={5} color="green"/>}
        </Box>
    );
}
