import {Box, Card, CardBody, Divider, GridItem, Heading, Image, Stack, Text, Tooltip} from "@chakra-ui/react";
import {CopyIcon, TimeIcon} from '@chakra-ui/icons';
import HTMLParser from "../../utils/HtmlParser";
import {useEffect, useRef} from "react";
import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {useIntl} from "react-intl";

const Post = ({post, posts_count, created_at, id, refr, showBottomBox = true, ...rest}) => {
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const titleRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        setTextDirection(titleRef.current, post.title, 'justify');
        setTextDirection(textRef.current, post.description.replace(/<[^>]*>?/gm, ''));
    }, [post]);

    const navigate = useNavigate();

    return (
        <GridItem>
            {/*<Card maxW='25vw' mb={10} ref={refr}>*/}
            <Card mb={10} ref={refr}
                  onClick={() => hasPermission('raqib4', 'fe_view_cluster') ? navigate(`/raqib4/${id}`) : null}
                  cursor={'pointer'}>
                <CardBody textAlign={'start'} p={0} bgColor={"white"}>
                    <Box style={{display: 'relative'}}>
                        {post.image && <Image src={post.image} w={'100%'} alt={post.title}/>}
                    </Box>

                    <Stack mt='6' spacing='3' p={3}>
                        <Heading size='md' fontFamily={"Cairo"} ref={titleRef}>{post.title}</Heading>
                        <Box ref={textRef} noOfLines={7} className={"accented-scrollbar"} overflowY={"scroll"}
                             fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'}>
                            <HTMLParser htmlContent={post.description}/>
                        </Box>
                    </Stack>

                    {showBottomBox && <Box className="bottom-text-box">
                        <Box backgroundColor={"gray.800"} color={'white'} p={5}>

                            <Text>
                                <CopyIcon me={3}/>
                                {intl.formatMessage({
                                    id: 'raqib4.repeatedInPosts',
                                    defaultMessage: 'Repeated in posts'
                                })}: {posts_count}
                            </Text>
                            <Tooltip label={gregorianJalaliDTConversion(created_at)} aria-label='datetime-conversion'>
                                <Text>
                                    <TimeIcon me={3}/>
                                    {dtToLocaleString(created_at)}
                                </Text>
                            </Tooltip>

                        </Box>
                    </Box>}

                </CardBody>
                <Divider/>
            </Card>
        </GridItem>
    );
};

export default Post;