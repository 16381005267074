import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import Farsi from '../../lang/fa.json';
import Arabic from '../../lang/ar.json';
import EnglishUS from '../../lang/en-US.json';


export const LocaleContext = React.createContext();

const langs = {
    'en-US': {messages: EnglishUS, isRTL: false},
    'fa': {messages: Farsi, isRTL: true},
    'ar': {messages: Arabic, isRTL: true}
}

const LocaleProvider = (props) => {
    const [locale, setLocale] = useState('');
    const [messages, setMessages] = useState();
    const [isRTL, setIsRTL] = useState(false);

    useEffect(() => {
        const storedLocale = localStorage.getItem('locale');
        const defLocale = Object.keys(langs).includes(navigator.language) ? navigator.language : Object.keys(langs)[0];
        setLocale(storedLocale || defLocale);
    }, []);

    useEffect(() => {
        setMessages(langs[locale]?.messages);
        setIsRTL(langs[locale]?.isRTL);
        localStorage.setItem('locale', locale);
    }, [locale]);

    function changeLanguage() {
        const locales = Object.keys(langs);
        let localeIndex = locales.indexOf(locale);
        localeIndex = (localeIndex + 1) % locales.length;
        setLocale(locales[localeIndex]);
    }

    return (
        <LocaleContext.Provider value={{locale, isRTL, changeLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
}

export default LocaleProvider;