import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Flex,
    Heading,
    Image,
    Link,
    Spinner,
    Text,
    Tooltip
} from "@chakra-ui/react";
import {configs} from "../../Configs";
import {useEffect, useRef, useState} from "react";
import HTMLParser from "../../utils/HtmlParser";
import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import {useIntl} from "react-intl";
import useAxios from "../../hooks/useAxois";
import Select, {components} from "react-select";
import makeAnimated from 'react-select/animated';
import {FaRegSave} from 'react-icons/fa';

export const PostRowWithTagForm = ({
                                       allPostTags,
                                       image = null,
                                       title,
                                       description,
                                       published_at,
                                       source,
                                       link,
                                       refr,
                                       id,
                                       tags,
                                       resource,
                                       ...rest
                                   }) => {
    const intl = useIntl();
    const titleRef = useRef(null);
    const textRef = useRef(null);
    const [showMore, setShowMore] = useState(false);
    const axiosInstance = useAxios();
    const [postTags, setPostTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [changeColor, setChangeColor] = useState(tags.length > 0);
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        setPostTags(allPostTags?.filter(tgi => tags.filter(tg => tg.is_static).length > 0 || !tgi?.is_static).map(tagItem => ({
            label: tagItem.title,
            value: tagItem.tag_id
        })))
    }, []);

    useEffect(() => {
        const theTagIds = tags.map(tg => tg.tag_id)
        setSelectedTags(postTags.filter(tagItem => theTagIds.includes(tagItem.value)))
    }, [postTags]);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        setTextDirection(textRef.current, description.replace(/<[^>]*>?/gm, ''));
    }, [title, description]);

    const handleTagsChange = (selectedOptions) => {
        const tagIds = selectedOptions.map(tg => tg.value);
        setSelectedTags(postTags.filter(tagItem => tagIds.includes(tagItem.value)))
    }

    const saveTags = (e) => {
        setIsLoading(true);
        setError(false);
        const theTagIds = selectedTags ? selectedTags.map(tag => tag.value) : [];
        axiosInstance.put(`/raqib5/${id}`, theTagIds).then((res) => {
            setIsLoading(false);
            setError(false);
            setChangeColor(theTagIds.length > 0);
        }).catch((error) => {
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
    }

    const animatedComponents = makeAnimated();

    const CustomMultiValueLabel = props => (
        <components.MultiValueLabel {...props}>
            <Tooltip label={props.data.label}>{props.children}</Tooltip>
        </components.MultiValueLabel>
    );

    const thePlatform = configs.raqib5.rss_platforms.find(item => item.value === source);

    return (<Card ref={refr} mb={1} style={{overflow: 'hidden'}}
                  onMouseEnter={() => setShowMore(true)}
                  onMouseLeave={() => setShowMore(false)}
        >
            <Box textAlign="center" style={{backgroundColor: `rgb(${configs.raqib5.colors[source]})`}}
                 color={'white'}
                 borderTopRightRadius='lg'
                 borderTopLeftRadius='lg'
            >
                <Link href={resource.origin_url || link} isExternal>
                    [{resource.title}] - {intl.formatMessage({
                    id: "raqib5.on",
                    defaultMessage: "On"
                })} {intl.formatMessage({
                    id: `raqib5.${thePlatform.value}`,
                    defaultMessage: thePlatform.label
                })}
                </Link>
            </Box>
            <Box p={2} backgroundColor={"red.100"}>
                <Flex
                    width={'100%'}
                    direction={"row"}
                    gap={1}
                >
                    <Box w={'100%'}>
                        <Select
                            components={{...animatedComponents, MultiValueLabel: CustomMultiValueLabel}}
                            placeholder={intl.formatMessage({id: 'raqib5.tags', defaultMessage: 'Tags'})}
                            isMulti
                            options={postTags}
                            value={selectedTags}
                            onChange={handleTagsChange}
                            styles={{
                                multiValue: (base) => ({
                                    ...base,
                                    margin: 1,
                                    minHeight: '20px',
                                    height: '20px',
                                }),
                                multiValueLabel: (base) => ({
                                    ...base,
                                    fontSize: '10px',
                                    padding: '2px',
                                }),
                                multiValueRemove: (base) => ({
                                    ...base,
                                    height: '24px',
                                    width: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                    flexWrap: "nowrap",
                                    overflow: "hidden",
                                    padding: '2px'
                                }),
                                multiValueContainer: (base) => ({
                                    ...base,
                                    display: "flex",
                                }),
                            }}
                        />
                    </Box>
                    <Box>
                        <Tooltip label={intl.formatMessage({id: 'raqib5.save', defaultMessage: "Save"})}>
                            <Button style={{
                                whiteSpace: 'nowrap',
                                height: '38px',
                                width: '38px',
                                padding: 0
                            }}
                                    onClick={saveTags}
                                    colorScheme={changeColor ? 'cyan' : 'blue'}>{isLoading ?
                                <Spinner color="white"/> : <FaRegSave/>}</Button>
                        </Tooltip>
                    </Box>
                </Flex>
            </Box>
            <Flex>
                {image && showMore && <Box flex="1" alignItems={"center"}>
                    <Center h={'100%'}>
                        <Image
                            w={'100%'}
                            src={image}
                            alt={title}
                        />
                    </Center>
                </Box>}
                <Box flex={image && showMore ? 3 : 1}>
                    <CardHeader p={2}>
                        <Link href={link} isExternal>
                            <Heading size='sm' fontFamily={"Cairo"} ref={titleRef}>{title}</Heading>
                        </Link>
                    </CardHeader>

                    <CardBody textAlign={'left'} hidden={!showMore}>
                        <Box ref={textRef} noOfLines={2} className={"accented-scrollbar"} overflowY={"scroll"}
                             fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'}>
                            <HTMLParser htmlContent={description}/>
                        </Box>
                    </CardBody>

                    {showMore &&
                        <Text textAlign={"right"} p={2} color='gray.500' fontSize='sm'>
                            <Tooltip label={gregorianJalaliDTConversion(published_at)} aria-label='datetime-conversion'>
                                {dtToLocaleString(published_at)}
                            </Tooltip>
                        </Text>}
                </Box>
            </Flex>
        </Card>
    );
}