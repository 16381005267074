import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Flex,
    Heading,
    Image,
    Link,
    Spacer,
    Spinner,
    Stack,
    Text,
    Tooltip
} from "@chakra-ui/react";
import {ArrowForwardIcon, ExternalLinkIcon} from "@chakra-ui/icons"
import {configs} from "../../Configs";
import {useEffect, useRef, useState} from 'react';
import {dtToLocaleString, setTextDirection} from '../../utils/helpers';
import HTMLParser from "../../utils/HtmlParser";
import useAxios from "../../hooks/useAxois";
import Select, {components} from "react-select";
import makeAnimated from 'react-select/animated';
import {FaGoogle, FaPencilAlt, FaRegSave} from 'react-icons/fa';
import {useIntl} from "react-intl";
import {useAuth} from "../../hooks/useAuth";

export const PostGridWithTagForm = ({
                                        allPostTags,
                                        image = null,
                                        title,
                                        description,
                                        published_at,
                                        source,
                                        link,
                                        tags,
                                        refr,
                                        id,
                                        resource,
                                        ...rest
                                    }) => {
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const axiosInstance = useAxios();
    const [postTags, setPostTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [changeColor, setChangeColor] = useState(tags.length > 0);
    const titleRef = useRef(null);
    const textRef = useRef(null);
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        setPostTags(allPostTags?.filter(tgi => tags.filter(tg => tg.is_static).length > 0 || !tgi?.is_static).map(tagItem => ({
            label: tagItem.title,
            value: tagItem.tag_id
        })))
    }, []);

    useEffect(() => {
        const theTagIds = tags.map(tg => tg.tag_id)
        setSelectedTags(postTags.filter(tagItem => theTagIds.includes(tagItem.value)))
    }, [postTags]);

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        setTextDirection(textRef.current, description.replace(/<[^>]*>?/gm, ''));
    }, [title, description]);

    const handleTagsChange = (selectedOptions) => {
        const tagIds = selectedOptions.map(tg => tg.value);
        setSelectedTags(postTags.filter(tagItem => tagIds.includes(tagItem.value)))
    }

    const saveTags = (e) => {
        setIsLoading(true);
        setError(false);
        const theTagIds = selectedTags ? selectedTags.map(tag => tag.value) : [];
        axiosInstance.put(`/raqib7/${id}`, theTagIds).then((res) => {
            setIsLoading(false);
            setError(false);
            setChangeColor(theTagIds.length > 0);
        }).catch((error) => {
            setIsLoading(false);
            if (error.response && error.response.status === 404) return;
            setError(true);
            console.error(error);
        });
    }

    const animatedComponents = makeAnimated();

    const CustomMultiValueLabel = props => (
        <components.MultiValueLabel {...props}>
            <Tooltip label={props.data.label}>{props.children}</Tooltip>
        </components.MultiValueLabel>
    );

    const thePlatform = configs.raqib7.rss_platforms.find(item => item.value === source);

    return (<Card ref={refr} mb={3}>
            <Box textAlign="center" style={{backgroundColor: `rgb(${configs.raqib7.colors[source]})`}}
                 color={'white'}
                 borderTopRightRadius='lg'
                 borderTopLeftRadius='lg'
            >
                <Link href={resource.origin_url || link} isExternal>
                    [{resource.title}] - {intl.formatMessage({
                    id: "raqib7.on",
                    defaultMessage: "On"
                })} {intl.formatMessage({
                    id: `raqib7.${thePlatform.value}`,
                    defaultMessage: thePlatform.label
                })}
                </Link>
            </Box>
            <CardHeader backgroundColor={"red.100"}>
                <Flex
                    width={'100%'}
                    direction={"row"}
                    gap={1}
                >
                    <Box w={'100%'}>
                        <Select
                            components={{...animatedComponents, MultiValueLabel: CustomMultiValueLabel}}
                            placeholder={intl.formatMessage({id: 'raqib7.tags', defaultMessage: 'Tags'})}
                            isMulti
                            options={postTags}
                            value={selectedTags}
                            onChange={handleTagsChange}
                            styles={{
                                multiValue: (base) => ({
                                    ...base,
                                    margin: 1,
                                    minHeight: '20px',
                                    height: '20px',
                                }),
                                multiValueLabel: (base) => ({
                                    ...base,
                                    fontSize: '10px',
                                    padding: '2px',
                                }),
                                multiValueRemove: (base) => ({
                                    ...base,
                                    height: '24px',
                                    width: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                    flexWrap: "nowrap",
                                    overflow: "hidden",
                                    padding: '2px'
                                }),
                                multiValueContainer: (base) => ({
                                    ...base,
                                    display: "flex",
                                }),
                            }}
                        />
                    </Box>
                    <Box>
                        <Tooltip label={intl.formatMessage({id: 'raqib7.save', defaultMessage: "Save"})}>
                            <Button style={{
                                whiteSpace: 'nowrap',
                                height: '38px',
                                width: '38px',
                                padding: 0
                            }}
                                    onClick={saveTags}
                                    colorScheme={changeColor ? 'cyan' : 'blue'}>{isLoading ?
                                <Spinner color="white"/> : <FaRegSave/>}</Button>
                        </Tooltip>
                    </Box>
                </Flex>
            </CardHeader>
            <CardBody textAlign={'left'}>

                {image && <Image
                    w={'100%'}
                    src={image}
                    alt={title}
                    borderBottomRightRadius='lg'
                    borderBottomLeftRadius='lg'
                />}
                <Stack mt='6' spacing='3'>
                    <Heading size='md' fontFamily={"Cairo"} ref={titleRef}>{title}</Heading>
                    <Text ref={textRef}
                        // noOfLines={7}
                          className={"accented-scrollbar"} overflowY={"scroll"}
                          fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'}>
                        <HTMLParser htmlContent={description}/>
                    </Text>
                </Stack>
            </CardBody>
            <Divider/>
            <Flex>
                <Box p='2'>
                    <ButtonGroup spacing='2'>
                        <Tooltip
                            label={intl.formatMessage({id: 'raqib7.viewInSource', defaultMessage: "View in source"})}>
                            <Link href={link} isExternal>
                                <ExternalLinkIcon color={`rgb(${configs.raqib7.colors[source]})`} mx="2px"/>
                            </Link>
                        </Tooltip>
                    </ButtonGroup>
                    <Tooltip label={intl.formatMessage({
                        id: 'raqib7.showInGoogleTranslate',
                        defaultMessage: "Show in google translate"
                    })}>
                        <Link ms={1}
                              href={'https://translate.google.com/#view=home&op=translate&sl=auto&tl=fa&text=' + encodeURIComponent(description.replace(/<[^>]*>?/gm, ''))}
                              isExternal><FaGoogle
                            style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                            color="blue"/></Link>
                    </Tooltip>
                    <Tooltip
                        label={intl.formatMessage({id: 'raqib7.shareOnTelegram', defaultMessage: "Share on telegram"})}>
                        <Link ms={1}
                              href={`https://t.me/share/url?url=${link}&text=` + encodeURIComponent(description.replace(/<[^>]*>?/gm, ''))}
                              isExternal><ArrowForwardIcon color="cyan.400"/></Link>
                    </Tooltip>

                    {source !== 'NEWS' && hasPermission('post', 'fe_edit') && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.editPost'})}>
                        <Link ms={1} href={`/posts/${id}?withEnemyTags`} isExternal>
                            <FaPencilAlt style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                         color="olive"/>
                        </Link>
                    </Tooltip>}

                    {source === 'NEWS' && hasPermission('news', 'fe_edit') && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.editArticle'})}>
                        <Link ms={1} href={`/news_articles/${id}?withEnemyTags`}>
                            <FaPencilAlt style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                         color="olive"/>
                        </Link>
                    </Tooltip>}
                </Box>
                <Spacer/>
                <Box p='2'>
                    <Text color='gray.500' fontSize='sm'>{dtToLocaleString(published_at)}</Text>
                </Box>
            </Flex>
        </Card>
    );
}